import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'
import { ICourse, ICoursePayload } from '@store/features/course/course.types'

export const createCourseThunk = createAsyncThunk(
  'courses/create',
  async (payload: ICoursePayload, { rejectWithValue }) => {
    try {
      await Api.course.createCourse(payload)

      return {}
    } catch (error: any) {
      return rejectWithValue(error.data.details[0].message as string)
    }
  }
)

export const getCourseThunk = createAsyncThunk(
  'course/get',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.course.getCourse(id)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCoursesThunk = createAsyncThunk(
  'courses/get',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.course.getCourses(url)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateActivationCourseThunk = createAsyncThunk(
  'courses/update_activation',
  async (course: ICourse, { rejectWithValue }) => {
    try {
      const response = await Api.course.updateTheActivationOfCourse(course)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCourseThunk = createAsyncThunk(
  'courses/update',
  async (course: ICoursePayload, { rejectWithValue }) => {
    try {
      const response = await Api.course.update(course)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteCourseThunk = createAsyncThunk(
  'courses/delete',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      await Api.course.deleteCourse(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

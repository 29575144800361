import { memo } from 'react'

import { FiltersStatusesEnum, TicketCounterStatuses } from '@appTypes/enums/filters'
import { ITicket } from '@appTypes/global'
import { CoursesTypography } from '@components/TicketBox/styled'

const RenderTicketCourses = ({ page, ticket }: { page: string; ticket: ITicket }) => {
  const { ticketCourses, ticketCourse, offerStatus } = ticket

  if (page === 'TicketsHistory' && ticket.status === FiltersStatusesEnum.APPROVED) {
    return <CoursesTypography>{ticketCourse?.course?.name}</CoursesTypography>
  }

  if (page === 'OpenTickets' && offerStatus === TicketCounterStatuses.PENDING) {
    return <CoursesTypography>{ticketCourse?.course?.name}</CoursesTypography>
  }

  return (
    <>
      {ticketCourses?.map((course) => {
        return <CoursesTypography key={`${course?.id}`}>{course?.course?.name}</CoursesTypography>
      })}
    </>
  )
}

export default memo(RenderTicketCourses)

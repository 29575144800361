import { AxiosResponse } from 'axios'
import { http } from '@api/RequestApi'

import { ICourse, ICoursePayload, ICourseResponse } from '@store/features/course/course.types'
import { BaseAPI } from './ApiServices'

class CoursesService extends BaseAPI {
  private prefix = '/courses'

  public async getCourse(id: string) {
    BaseAPI.isUserExist()

    const response = await http.get<any, AxiosResponse<ICourseResponse<ICourse>>>(
      `${this.prefix}/${id}/info`
    )

    return response?.data
  }

  public async getCourses(url: string) {
    BaseAPI.isUserExist()

    const response = await http.get<any, AxiosResponse<ICourseResponse<ICourse[]>>>(
      `${this.prefix}?${url}`
    )

    return response?.data
  }

  public async createCourse(data: ICoursePayload) {
    return http.post<ICoursePayload, AxiosResponse<string>>(`${this.prefix}`, data)
  }

  public async deleteCourse(id: string) {
    return http.delete<void, AxiosResponse<string>>(`${this.prefix}/${id}`)
  }

  public async update(data: ICoursePayload) {
    return http.put<ICoursePayload, AxiosResponse<string>>(`${this.prefix}/${data.id}`, data)
  }

  public async updateTheActivationOfCourse(data: ICourse) {
    return http.put<ICourse, AxiosResponse<string>>(`${this.prefix}/${data.id}`, data)
  }
}

export default CoursesService

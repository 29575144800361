import { Box, styled, css } from '@mui/material'

export const SuccessStepContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PageSubtitleBox = styled(Box)`
  max-width: 370px;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
    }
  `}
`

export const Content = styled(Box)`
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 40px;
    }
  `}
`

export const ButtonWrapper = styled(Box)`
  max-width: 280px;
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    height: 50px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: 350px;
      margin-top: 78px;
    }
  `}
`

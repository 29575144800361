import { useEffect } from 'react'

const useDebounceWithCallback = (value: any, callback: () => void, delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => callback(), delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])
}

export default useDebounceWithCallback

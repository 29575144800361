import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'unset',
    gap: '10px'
  }
}))

/* eslint-disable newline-per-chained-call */
import { useContext, useMemo, Dispatch, SetStateAction } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'

import { dayJsWrap } from '@utils/time'
import { FiltersParamsKeysEnum } from '@appTypes/enums/filters'
import { FiltersContext } from '@contexts/filters'
import { DialogAlertTicketFilterByDateViaState } from '@components/DropDownFilterByDate/types'

interface IUseStaticDateFiltersSection {
  setIsCalendar: Dispatch<SetStateAction<DialogAlertTicketFilterByDateViaState>>
}

const useStaticDateFiltersSection = ({ setIsCalendar }: IUseStaticDateFiltersSection) => {
  const [searchParams] = useSearchParams()
  const { dateFrom, dateTo } = FiltersParamsKeysEnum
  const { collectQueryParams } = useContext(FiltersContext)

  const handleStart = (value: Dayjs) => {
    setIsCalendar((prev) => ({
      ...prev,
      values: {
        dateFrom: value,
        dateTo: null
      }
    }))

    collectQueryParams(
      {
        [dateFrom]: value.format('YYYY-MM-DD')
      },
      [dateFrom, dateTo]
    )
  }

  const handleStartFromTo = (from: Dayjs, to: Dayjs) => {
    setIsCalendar((prev) => ({
      ...prev,
      values: {
        dateFrom: from,
        dateTo: to
      }
    }))

    collectQueryParams({
      [dateFrom]: from.format('YYYY-MM-DD'),
      [dateTo]: to.format('YYYY-MM-DD')
    })
  }

  const validateBeforeUse = ({ value, fromTo }: any) => {
    const hasDateFrom = searchParams.has('dateFrom')
    const hasDateTo = searchParams.has('dateTo')
    const gotDateFrom = searchParams.get('dateFrom')
    const gotDateTo = searchParams.get('dateTo')

    if (
      (value && !hasDateFrom && hasDateTo && value.format('YYYY-MM-DD') === gotDateTo) ||
      (value && !hasDateTo && hasDateFrom && value.format('YYYY-MM-DD') === gotDateFrom)
    ) {
      return
    }

    if (
      fromTo &&
      hasDateFrom &&
      hasDateTo &&
      fromTo.dateFrom.format('YYYY-MM-DD') === gotDateFrom &&
      fromTo.dateTo.format('YYYY-MM-DD') === gotDateTo
    ) {
      return
    }

    if (fromTo) {
      handleStartFromTo(fromTo.dateFrom, fromTo.dateTo)
      return
    }

    handleStart(value)
  }

  const startDates = useMemo(() => {
    return [
      { title: 'Today', value: dayJsWrap(dayjs().toISOString()) },
      { title: 'Yesterday', value: dayJsWrap(dayjs().subtract(1, 'day').toISOString()) },
      {
        title: 'This Month',
        fromTo: {
          [FiltersParamsKeysEnum.dateFrom]: dayJsWrap(
            dayjs().startOf('month').add(1, 'days').toISOString()
          ),
          [FiltersParamsKeysEnum.dateTo]: dayJsWrap(dayjs().endOf('month').toISOString())
        }
      },
      {
        title: 'Past Month',
        fromTo: {
          [FiltersParamsKeysEnum.dateFrom]: dayJsWrap(
            dayjs().subtract(1, 'month').startOf('month').add(1, 'days').toISOString()
          ),
          [FiltersParamsKeysEnum.dateTo]: dayJsWrap(
            dayjs().subtract(1, 'month').endOf('month').add(1, 'days').toISOString()
          )
        }
      },
      {
        title: '3 Past Month',
        fromTo: {
          [FiltersParamsKeysEnum.dateFrom]: dayJsWrap(
            dayjs().subtract(3, 'month').startOf('month').add(1, 'days').toISOString()
          ),
          [FiltersParamsKeysEnum.dateTo]: dayJsWrap(dayjs().toISOString())
        }
      }
    ]
  }, [])

  return { startDates, validateBeforeUse }
}

export { useStaticDateFiltersSection }

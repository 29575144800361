import { memo, useState } from 'react'

import type { ICourse } from '@store/features/course/course.types'

import Box from '@mui/material/Box'

import {
  deleteCourseThunk,
  updateActivationCourseThunk
} from '@store/features/course/course.actions'
import { setPopUpData } from '@store/features/general/general.slice'
import { useAppDispatch } from '@store/hooks'
import { Button, LinkTo } from '@components/common'
import { PAGE_ROUTES_BASE_PRIVATE } from '@appTypes/enums/pages'
import {
  Section,
  Title,
  SubTitles,
  StyledDoubleBox,
  StyledAddressBox,
  ButtonsContainer,
  StyledNoteBox,
  ValueContainer,
  NoteValueContainer,
  MainWrapper
} from './styled'

interface ICourseBox extends ICourse {}

const CourseBox = ({
  id,
  name,
  phone,
  email,
  address,
  guestFee,
  isActive,
  memberFee,
  notes
}: ICourseBox) => {
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState<string | null>(null)
  const [isAct, setIsAct] = useState<boolean>(isActive)

  return (
    <Section>
      <Title>{name}</Title>
      <MainWrapper>
        <StyledDoubleBox>
          <Box>
            <SubTitles>Phone Number</SubTitles>
            <ValueContainer>{phone}</ValueContainer>
          </Box>
          <Box>
            <SubTitles>Email</SubTitles>
            <ValueContainer>{email}</ValueContainer>
          </Box>
        </StyledDoubleBox>
        <StyledAddressBox>
          <SubTitles>Course Address</SubTitles>
          <ValueContainer>{address}</ValueContainer>
        </StyledAddressBox>
        <StyledDoubleBox>
          <Box>
            <SubTitles>Member Fee</SubTitles>
            <ValueContainer>${memberFee}</ValueContainer>
          </Box>
          <Box>
            <SubTitles>Guest Fee</SubTitles>
            <ValueContainer>${guestFee}</ValueContainer>
          </Box>
        </StyledDoubleBox>
        <StyledNoteBox>
          <SubTitles>Course Note</SubTitles>
          <NoteValueContainer>{notes}</NoteValueContainer>
        </StyledNoteBox>
      </MainWrapper>
      <ButtonsContainer>
        <LinkTo href={`${PAGE_ROUTES_BASE_PRIVATE.UPDATE_COURSE_BASE}/${id}`}>
          <Button variant='contained' color='primary' type='button'>
            Edit
          </Button>
        </LinkTo>
        <Button
          variant='outlined'
          color='primary'
          type='button'
          isLoading={loader === 'hide'}
          onClick={() => {
            setLoader('hide')
            dispatch(
              updateActivationCourseThunk({
                id,
                name,
                phone,
                email,
                address,
                guestFee,
                memberFee,
                notes,
                isActive: !isActive
              })
            )
              .then(() => {
                setLoader(null)
                setIsAct((prev) => !prev)
              })
              .catch(() => {
                setLoader(null)
              })
          }}
        >
          {isAct ? 'Hide' : 'Unhide'}
        </Button>

        <Button
          variant='outlined'
          color='primary'
          type='button'
          isLoading={loader === 'delete'}
          onClick={() => {
            setLoader('delete')
            dispatch(deleteCourseThunk({ id }))
              .then(() => {
                dispatch(setPopUpData({ status: true, popUpId: id }))
                setLoader(null)
              })
              .catch(() => {
                setLoader(null)
              })
          }}
        >
          Delete
        </Button>
      </ButtonsContainer>
    </Section>
  )
}

export default memo(CourseBox)

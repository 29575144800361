import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const Section = styled(Box)(({ theme }) => ({
  width: '500px',
  margin: '60px auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.MuiButtonBase-root': {
    width: '250px',
    marginTop: '50px',
    a: {
      color: 'white',
      textDecoration: 'none'
    }
  },

  [theme.breakpoints.down('md')]: {}
}))

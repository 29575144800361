import { memo } from 'react'

import { CircularProgress } from '@mui/material'

interface ICircularLoader {
  width: number
  height: number
}

const CircularLoader = ({ width, height, ...rest }: ICircularLoader) => {
  return <CircularProgress style={{ width, height }} {...rest} />
}

export default memo(CircularLoader)

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Section = styled(Box)(() => ({
  maxWidth: '440px',
  margin: '0 auto',

  '.MuiTypography-h1': {
    textAlign: 'center'
  },

  '.MuiBox-root': {
    maxWidth: '300px',
    margin: 'auto auto'
  },

  '.MuiTypography-body2': {
    fontSize: '15px',
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  }
}))

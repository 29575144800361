import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Section = styled(Box)(() => ({
  height: '20vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

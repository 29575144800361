/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'

import { createPortal } from 'react-dom'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '@store/hooks'

import useAlert from '@hook/useAlert'
import { createTicketThunk, updateOwnTicketThunk } from '@store/features/ticket/ticket.actions'
import {
  clearTicketStorage,
  getInitialActiveStep,
  saveActiveStepDataInStorage
} from '../../helpers/stepperHelpers'
import { getCreateTicketPayload, stepsToShowStepConnector } from '../../helpers/createTicketHelpers'
import {
  IEnterCommentFormValues,
  IPickCourseFormValues,
  ISelectDateFormValues,
  ISelectGroupsFormValues,
  ISelectMembersFormValues
} from '../../types'
import PickCourseStep from './components/PickCourseStep'
import SelectDateStep from './components/SelectDateStep'
import EnterCommentStep from './components/EnterCommentStep'
import ConfirmStep from './components/ConfirmStep'
import SuccessStep from './components/SuccessStep'
import { Steps } from '../../constants'
import {
  Step,
  StepConnector,
  StepIcon,
  StepInfo,
  StepLabel,
  Stepper,
  StepperBox,
  StepperContainer
} from './styled'
import { getPickCourseInitialValues, initialCoursesData } from '../../helpers/pickCourseHelpers'
import { getSelectDateInitialValues, initialDateData } from '../../helpers/selectDateHelpers'
import {
  initialMembersData,
  addNewGroupInLocalStorage,
  deleteGroupFromLocalStorage,
  getSelectMembersInitialValues,
  getSelectedGroupsFromLocalStorage
} from '../../helpers/selectMembersHelpers'
import { getCommentInitialValue, initialCommentData } from '../../helpers/enterCommentHelpers'
import SelectGroupsStep from './components/SelectGroupsStep'

const SelectCourseStepper = () => {
  const { showErrorToast } = useAlert()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // hereeeeeeeee
  // @ts-ignore
  const { userInfo = {} as any } = useAppSelector(({ auth }) => auth)

  const [activeStep, setActiveStep] = useState<Steps>(getInitialActiveStep)
  const [coursesInfo, setCoursesInfo] = useState<IPickCourseFormValues>(getPickCourseInitialValues)
  const [dateInfo, setDateInfo] = useState<ISelectDateFormValues>(getSelectDateInitialValues)
  const [membersInfo, setMembersInfo] = useState<ISelectMembersFormValues>(() =>
    getSelectMembersInitialValues()
  )

  const initialGroupItem = {
    members: []
  }

  const initialGroupsData = [{ ...initialGroupItem, id: `${Math.random()}` }]

  const [groupsInfo, setGroupsInfo] = useState<ISelectGroupsFormValues[]>(
    getSelectedGroupsFromLocalStorage(initialGroupsData)
  )

  const [commentInfo, setCommentInfo] = useState<IEnterCommentFormValues>(getCommentInitialValue)
  const [mounted, setMounted] = useState(false)

  const changeActiveStep = (step: number) => {
    setActiveStep((prevActiveStep) => {
      const currentActiveStep = prevActiveStep + step

      saveActiveStepDataInStorage(currentActiveStep)

      return currentActiveStep
    })
  }

  const handleBack = () => {
    if (activeStep === Steps.COURSE) {
      return
    }

    changeActiveStep(-1)
  }

  const handleBackToDatePicket = () => {
    saveActiveStepDataInStorage(Steps.DATE)
    setActiveStep(Steps.DATE)
  }

  const handleNext = () => {
    if (activeStep === Steps.SUCCESS) {
      return
    }

    changeActiveStep(1)
  }

  const handleCourseChange = (data: IPickCourseFormValues) => {
    setCoursesInfo(data)
    handleNext()
  }

  const handleDateChange = (data: ISelectDateFormValues) => {
    setDateInfo(data)
    handleNext()
  }

  const handleMembersChange = (data: ISelectMembersFormValues) => {
    setMembersInfo(data)
    handleNext()
  }

  const handleGroupsNext = (data: ISelectGroupsFormValues[]) => {
    setGroupsInfo(data)
    handleNext()
  }

  const handleGroupsUpdateAfterMemberChange = (groups: ISelectGroupsFormValues[]) => {
    setGroupsInfo(groups)
  }

  const handleGroupsCreate = () => {
    const newGroup = { ...initialGroupItem, id: `${Math.random()}` }

    setGroupsInfo((prev) => {
      return [...prev, { ...newGroup }]
    })

    addNewGroupInLocalStorage(newGroup)
  }

  const handleGroupDelete = (groupId: string) => {
    setGroupsInfo((prev) => {
      const newItems = prev.filter((group) => {
        return group.id !== groupId
      })

      return [...newItems]
    })
    deleteGroupFromLocalStorage(groupId)
  }

  const handleCommentChange = ({ text }: IEnterCommentFormValues) => {
    setCommentInfo({ text })
    handleNext()
  }

  const handleReset = () => {
    clearTicketStorage()

    setCoursesInfo(initialCoursesData)
    setDateInfo(initialDateData)
    setMembersInfo(initialMembersData)
    setGroupsInfo(
      getSelectedGroupsFromLocalStorage([{ ...initialGroupItem, id: `${Math.random()}` }])
    )
    setCommentInfo(initialCommentData)
  }

  const handleConfirm = async (date: Date) => {
    const makeGroupsPayload = groupsInfo.map((group) => {
      return {
        members: group.members.filter(({ isMember }) => isMember).map(({ id }) => id),
        guests: group.members.filter(({ isMember }) => !isMember).map(({ fullName }) => fullName)
      }
    })

    const ticketPayload = getCreateTicketPayload({
      date,
      groups: makeGroupsPayload,
      comment: commentInfo.text,
      interval: dateInfo.interval,
      courses: coursesInfo.courses
    })

    const ticketId = searchParams.get('ticketId')

    try {
      if (ticketId) {
        await dispatch(updateOwnTicketThunk({ id: ticketId, data: ticketPayload }))
      } else {
        const { data } = await dispatch(createTicketThunk({ data: ticketPayload })).unwrap()
        localStorage.setItem('created-ticket-id', data.id)
      }

      handleNext()
      handleReset()
    } catch (e) {
      showErrorToast('Something went wrong')
    }
  }

  const steps = useMemo(
    () => [
      {
        key: Steps.COURSE,
        component: <PickCourseStep defaultValues={coursesInfo} onNext={handleCourseChange} />
      },
      {
        key: Steps.DATE,
        component: (
          <SelectDateStep defaultValues={dateInfo} onNext={handleDateChange} onBack={handleBack} />
        )
      },
      {
        key: Steps.GROUPS,
        component: (
          <SelectGroupsStep
            defaultValues={groupsInfo}
            onNext={handleGroupsNext}
            onBack={handleBack}
            onCreate={handleGroupsCreate}
            onDelete={handleGroupDelete}
            onUpdate={handleGroupsUpdateAfterMemberChange}
          />
        )
      },
      {
        key: Steps.COMMENT,
        label: 'Comment',
        component: (
          <EnterCommentStep
            defaultValues={commentInfo}
            onNext={handleCommentChange}
            onBack={handleBack}
          />
        )
      },
      {
        key: Steps.CONFIRM,
        component: (
          <ConfirmStep
            date={dateInfo}
            courses={coursesInfo.courses}
            members={membersInfo.members}
            groups={groupsInfo}
            comment={commentInfo.text}
            loading={false} // isLoading || isUpdateLoading
            onNext={handleConfirm}
            onBack={handleBack}
            onBackToDatePicker={handleBackToDatePicket}
          />
        )
      },
      {
        key: Steps.SUCCESS,
        component: <SuccessStep setActiveStep={setActiveStep} />
      }
    ],
    [
      coursesInfo,
      handleCourseChange,
      dateInfo,
      handleDateChange,
      handleBack,
      membersInfo,
      handleMembersChange,
      commentInfo,
      handleCommentChange,
      // isLoading,
      // isUpdateLoading,
      handleConfirm,
      handleReset
    ]
  )

  const activeStepObject = useMemo(() => {
    return steps.find(({ key }) => key === activeStep)
  }, [activeStep, steps])

  const headerPortalElement = document.getElementById('header-middle')

  const showStepperConnector =
    mounted && headerPortalElement && stepsToShowStepConnector.includes(activeStep)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <>
      {showStepperConnector &&
        createPortal(
          <StepperBox>
            <Stepper connector={<StepConnector />} activeStep={activeStep}>
              {stepsToShowStepConnector.map((stepIndex) => (
                <Step key={stepIndex}>
                  <StepLabel StepIconComponent={StepIcon} />
                </Step>
              ))}
            </Stepper>
            <StepInfo>{`Step ${activeStep + 1} of ${stepsToShowStepConnector.length}`}</StepInfo>
          </StepperBox>,
          headerPortalElement as HTMLDivElement
        )}
      <StepperContainer>{activeStepObject?.component}</StepperContainer>
    </>
  )
}

export default SelectCourseStepper

import { memo } from 'react'

import Typography from '@mui/material/Typography'
import { Section } from './styled'

const Sent = () => {
  return (
    <Section>
      <Typography variant='h1'>Recovery Link Sent</Typography>
      <Typography component='p' variant='body2'>
        Please check your Email for the password recovery link
      </Typography>
    </Section>
  )
}

export default memo(Sent)

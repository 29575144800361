import { Dispatch, SetStateAction, memo, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useSearchParams } from 'react-router-dom'
import NpmInfiniteScroll from 'react-infinite-scroll-component'

import { IPagination } from '@appTypes/global'

import { FiltersContext } from '@contexts/filters'
import { initialPagination } from '@constants/routes'
import CircularLoader from '../common/CircularLoader'
import { LoaderWrapper } from './styled'

/**
 *
 * If 'remoteController' argument is `true`,
 * that means in parent components must be another `setPagination` handler
 * This case exception for Upload Member Csv page.
 *
 */

interface IInfiniteScrollProps {
  total: number
  arrayLength: number
  remoteController?: boolean
  children: React.ReactNode
  setPagination?: Dispatch<SetStateAction<IPagination>>
}

const InfiniteScroll = (props: IInfiniteScrollProps) => {
  const [searchParams] = useSearchParams()

  const { children, arrayLength, total = 0, remoteController = false, setPagination } = props
  const { collectQueryParams } = useContext(FiltersContext)

  const isHasMore = remoteController ? false : arrayLength < total

  const handleLoadMore = () => {
    const offset = searchParams?.get('offset') as string

    setTimeout(() => {
      if (setPagination) {
        // members csv  case
        setPagination((prev) => {
          return {
            ...prev,
            offset: +prev.offset + initialPagination.limit
          }
        })
        return
      }

      if (collectQueryParams) {
        const newOffset = offset ? `${+offset + initialPagination.limit}` : '0'
        collectQueryParams({ offset: newOffset })
      }
    }, 700)
  }

  return (
    <NpmInfiniteScroll
      dataLength={arrayLength}
      hasMore={isHasMore}
      scrollableTarget={uuid()}
      next={() => handleLoadMore()}
      loader={
        <LoaderWrapper>
          <CircularLoader width={25} height={25} />
        </LoaderWrapper>
      }
    >
      {children}
    </NpmInfiniteScroll>
  )
}

export default memo(InfiniteScroll)

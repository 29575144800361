import { Dialog } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '10px'
  },
  [theme.breakpoints.down('md')]: {
    '> .MuiDialog-container > .MuiPaper-root': {
      margin: '0px',
      width: '90%'
    }
  }
}))

import {
  FiltersOrderValuesEnum,
  FiltersParamsKeysEnum,
  FiltersSortValuesEnum,
  FiltersStatusesEnum
} from '@appTypes/enums/filters'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { initialPagination } from '@constants/routes'
import { urlObjectParseToString } from '@utils/queryParser'

export const privateRoutesProps = {
  [PAGE_ROUTES_PRIVATE.OPEN_TICKETS]: {
    [FiltersParamsKeysEnum.status]: [FiltersStatusesEnum.PENDING],
    [FiltersParamsKeysEnum.sortBy]: FiltersSortValuesEnum.ASC,
    [FiltersParamsKeysEnum.orderBy]: FiltersOrderValuesEnum.date,
    [FiltersParamsKeysEnum.offset]: initialPagination.offset,
    [FiltersParamsKeysEnum.limit]: initialPagination.limit,
    [FiltersParamsKeysEnum.isFinished]: initialPagination.isFinished
  },
  [PAGE_ROUTES_PRIVATE.TICKET_HISTORY]: {
    // [FiltersParamsKeysEnum.statuses]: [
    //   FiltersStatusesEnum.PENDING,
    //   FiltersStatusesEnum.APPROVED,
    //   FiltersStatusesEnum.DECLINED,
    //   FiltersStatusesEnum.CANCELED,
    //   FiltersStatusesEnum.APPROVED_CANCELED
    // ],
    [FiltersParamsKeysEnum.sortBy]: FiltersSortValuesEnum.ASC,
    [FiltersParamsKeysEnum.orderBy]: FiltersOrderValuesEnum.date,
    [FiltersParamsKeysEnum.offset]: initialPagination.offset,
    [FiltersParamsKeysEnum.limit]: initialPagination.limit,
    [FiltersParamsKeysEnum.isFinished]: true
  },
  [PAGE_ROUTES_PRIVATE.All_COURSES]: {
    [FiltersParamsKeysEnum.offset]: initialPagination.offset,
    [FiltersParamsKeysEnum.limit]: initialPagination.limit,
    [FiltersParamsKeysEnum.sortBy]: FiltersSortValuesEnum.ASC,
    [FiltersParamsKeysEnum.orderBy]: FiltersOrderValuesEnum.name
  },
  [PAGE_ROUTES_PRIVATE.ALL_MEMBERS]: {
    [FiltersParamsKeysEnum.offset]: initialPagination.offset,
    [FiltersParamsKeysEnum.limit]: initialPagination.limit,
    [FiltersParamsKeysEnum.sortBy]: FiltersSortValuesEnum.ASC,
    [FiltersParamsKeysEnum.orderBy]: FiltersOrderValuesEnum.fullName
  }
}

export type privateRoutesPropsType =
  | (typeof privateRoutesProps)[PAGE_ROUTES_PRIVATE.OPEN_TICKETS]
  | (typeof privateRoutesProps)[PAGE_ROUTES_PRIVATE.TICKET_HISTORY]
  | (typeof privateRoutesProps)[PAGE_ROUTES_PRIVATE.All_COURSES]

export const headerPages = [
  {
    id: 'openTickets',
    params: `?${urlObjectParseToString(privateRoutesProps[PAGE_ROUTES_PRIVATE.OPEN_TICKETS])}`,
    link: PAGE_ROUTES_PRIVATE.OPEN_TICKETS,
    title: 'Open Tickets',
    options: null
  },
  {
    id: 'ticketHistory',
    params: `?${urlObjectParseToString(privateRoutesProps[PAGE_ROUTES_PRIVATE.TICKET_HISTORY])}`,
    link: PAGE_ROUTES_PRIVATE.TICKET_HISTORY,
    title: 'Ticket History',
    options: null
  },
  {
    id: 'courses',
    params: '',
    link: '',
    title: 'Courses',
    options: [
      {
        id: '',
        params: `?${urlObjectParseToString(privateRoutesProps[PAGE_ROUTES_PRIVATE.All_COURSES])}`,
        link: `courses`,
        title: 'All Courses',
        options: null
      },
      {
        id: '',
        params: ``,
        link: PAGE_ROUTES_PRIVATE.CREATE_COURSE,
        title: 'Create a Course',
        options: null
      }
    ]
  },
  {
    id: 'members',
    params: '',
    link: '',
    title: 'Members',
    options: [
      {
        id: '',
        params: ``,
        link: PAGE_ROUTES_PRIVATE.ALL_MEMBERS,
        title: 'All Members',
        options: null
      },
      {
        id: '',
        params: ``,
        link: PAGE_ROUTES_PRIVATE.ADD_MEMBER,
        title: 'Add Member',
        options: null
      }
    ]
  },
  {
    id: 'reports',
    params: '',
    link: PAGE_ROUTES_PRIVATE.REPORTS,
    title: 'Reports'
  },
  {
    id: 'change-password',
    params: '',
    link: PAGE_ROUTES_PRIVATE.CHANGE_PASSWORD,
    title: 'Change Password'
  },
  {
    id: '5',
    params: '',
    link: '',
    title: 'username',
    options: [
      {
        id: '',
        params: ``,
        link: '',
        title: 'Change Password',
        options: null
      },
      {
        id: '',
        params: ``,
        link: '',
        title: 'Log Out',
        options: null
      }
    ]
  }
]

import Box from '@mui/material/Box'
import { COLORS } from '@collections/colors'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Box)(() => ({
  '.MuiStack-root': {
    fieldset: {
      display: 'none'
    },
    input: {
      border: `1px solid ${COLORS.gray87} !important`
    },
    label: {
      transform: 'unset',
      textAlign: 'left',
      marginBottom: '10px'
    }
  }
}))

import React, { memo, ChangeEvent } from 'react'
import { StyledInput } from './styled'

interface ISearch {
  value: string
  placeholder: string
  endAdornment: React.ReactNode
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Search = (props: ISearch) => {
  return <StyledInput {...props} />
}

export default memo(Search)

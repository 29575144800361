import { memo } from 'react'

import { Button, LinkTo } from '@components/common'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { FiltersStatusesEnum, TicketCounterStatuses } from '@appTypes/enums/filters'

import { TicketOfferStatusType } from '@store/features/ticket/ticket.types'
import { generateEndDateByAvailability } from '@utils/time'
import { ticketButtonsHook } from './ticketButtonsHook'
import { StyledStatusTypography } from '../../styled'
import { ConfirmCancelationButton } from './styled'

interface ITicketBoxButtons {
  page: 'OpenTickets' | 'TicketsHistory'
  offerStatus: TicketOfferStatusType
  id: string
  date: string
  isFinished: boolean
  availability: number
  coursesLength: number
  membersLength: number
  status: FiltersStatusesEnum
}

const TicketBoxButtons = ({
  page,
  id,
  date,
  status,
  isFinished,
  offerStatus,
  availability,
  coursesLength,
  membersLength
}: ITicketBoxButtons) => {
  const end = generateEndDateByAvailability(date, availability)
  const { declineButton, counterButton, switchColor, switchTitle, confirmCancellation } =
    ticketButtonsHook({
      id,
      status
    })

  if (page === 'OpenTickets' && offerStatus === TicketCounterStatuses.PENDING) {
    return <div>Countered</div>
  }

  if (isFinished && status === FiltersStatusesEnum.PENDING) {
    return <div>Closed</div>
  }

  if (status === FiltersStatusesEnum.PENDING) {
    if (end.fromNow().includes('ago')) {
      return (
        <>
          {!membersLength || !coursesLength ? null : (
            <Button variant='contained' color='primary' type='button' disabled>
              Approve
            </Button>
          )}

          {declineButton()}
        </>
      )
    }

    return (
      <>
        {!membersLength || !coursesLength ? null : (
          <LinkTo href={`${PAGE_ROUTES_PRIVATE.OPEN_TICKETS}/${id}`}>
            <Button variant='contained' color='primary' type='button'>
              Approve
            </Button>
          </LinkTo>
        )}
        {declineButton()}

        {offerStatus === TicketCounterStatuses.NO_OFFER ? counterButton() : null}
      </>
    )
  }

  if (
    status === FiltersStatusesEnum.APPROVED ||
    status === FiltersStatusesEnum.DECLINED ||
    status === FiltersStatusesEnum.APPROVED_CANCELED
  ) {
    return <StyledStatusTypography color={switchColor()}>{switchTitle()}</StyledStatusTypography>
  }

  if (status === FiltersStatusesEnum.CANCELED) {
    return (
      <ConfirmCancelationButton
        variant='outlined'
        color='primary'
        type='button'
        onClick={() => {
          confirmCancellation()
        }}
      >
        Confirm Cancellation
      </ConfirmCancelationButton>
    )
  }

  return null
}

export default memo(TicketBoxButtons)

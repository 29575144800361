import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { COLORS } from '@collections/colors'

export const Wrapper = styled(Box)(() => ({
  marginTop: '16px'
}))

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  textDecoration: 'underline',
  color: COLORS.grayUnderline
}))

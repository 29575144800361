import { Box } from '@mui/material'
import { styled, keyframes } from '@mui/material/styles'

const rotate = keyframes`
  100% {
    background-position: -100% 0;
  }
`

export const ContentLoader = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: '1',
  background: `linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  )`,
  backgroundSize: '200% 100%',
  backgroundPosition: '100% 0',
  animation: `${rotate} 2s infinite`
}))

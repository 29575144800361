import { memo } from 'react'

import { ITicket } from '@appTypes/global'
import { Box, Typography } from '@mui/material'
import RenderMembersAndGuests from '../RenderMembersAndGuests'

const Members = ({ ticket }: { ticket: ITicket }) => {
  return (
    <Box>
      <Typography component='h5' variant='h5'>
        Members
      </Typography>
      <RenderMembersAndGuests ticket={ticket} />
    </Box>
  )
}

export default memo(Members)

import { styled } from '@mui/material/styles'

import Input from './components/Input'

export const StyledInput = styled(Input)<{ textarea?: string }>(({ textarea }) => ({
  '.MuiInputBase-inputMultiline': {
    height: textarea ? '93px' : 'auto',
    minHeight: textarea ? '93px' : 'auto'
  },
  '.MuiSvgIcon-root': {
    position: 'absolute',
    cursor: 'pointer',
    width: '19px',
    height: '14px',
    right: '16px',
    top: '18px'
  },
  input: {
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}))

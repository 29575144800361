import { ReactNode, memo } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import TicketLoaderWrapper from '@components/TicketLoaderWrapper'
import { Content } from './styled'

interface ITicketsMissingWrapper {
  children: ReactNode
  loader: boolean
  dataLength: number
  emptyMessage: string
  missingByKeyMessage: string
}

const MissingWrapper = ({
  loader,
  dataLength,
  children,
  emptyMessage,
  missingByKeyMessage
}: ITicketsMissingWrapper) => {
  const [searchParams] = useSearchParams()

  return (
    <TicketLoaderWrapper loader={loader}>
      <Content empty={!dataLength ? 'empty' : ''}>
        {!dataLength ? (
          <Typography component='p' variant='body2'>
            {searchParams.get('key') ? missingByKeyMessage : emptyMessage}
          </Typography>
        ) : (
          children
        )}
      </Content>
    </TicketLoaderWrapper>
  )
}

export default memo(MissingWrapper)

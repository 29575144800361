import PageTitle from '@ui/PageTitle'
import PageSubtitle from '@ui/PageSubtitle'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { Button } from '@components/common'
import { Dispatch, SetStateAction } from 'react'
import { Steps } from '@pages/CreateTicket/book-ticket/constants'
import { ButtonWrapper, Content, PageSubtitleBox, SuccessStepContainer } from './styled'

const SuccessStep = ({ setActiveStep }: { setActiveStep: Dispatch<SetStateAction<Steps>> }) => {
  const navigate = useNavigate()

  return (
    <SuccessStepContainer>
      <Content>
        <PageTitle textAlign='center'>Great! Your ticket has been created.</PageTitle>
        <PageSubtitleBox>
          <PageSubtitle textAlign='center'>
            You may now finish the reservation for this ticket, or create a new one.
          </PageSubtitle>
        </PageSubtitleBox>

        <ButtonWrapper display='flex' justifyContent='center'>
          <Button
            variant='contained'
            color='primary'
            type='button'
            onClick={() => {
              navigate(
                `${PAGE_ROUTES_PRIVATE.OPEN_TICKETS}/${localStorage.getItem('created-ticket-id')}`
              )
              localStorage.removeItem('created-ticket-id')
            }}
          >
            Reserve This Ticket
          </Button>
          <Button
            variant='outlined'
            color='primary'
            type='button'
            onClick={() => {
              navigate(PAGE_ROUTES_PRIVATE.HOME)
              localStorage.removeItem('created-ticket-id')
            }}
          >
            Back to Admin Panel
          </Button>
          <Button
            variant='outlined'
            color='primary'
            type='button'
            onClick={() => {
              localStorage.removeItem('created-ticket-id')
              setActiveStep(Steps.COURSE)
              navigate(PAGE_ROUTES_PRIVATE.CREATE_TICKET)
            }}
          >
            Create another one
          </Button>
        </ButtonWrapper>
      </Content>
    </SuccessStepContainer>
  )
}

export default SuccessStep

import { css, Dialog, styled, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import COLORS from '@app/colors'

export const SeeMoreDialogContainer = styled(Dialog)`
  width: 100%;

  .MuiPaper-root {
    width: 100%;
    background: transparent;
    width: max-content !important;
    max-width: 800px !important;
    margin: 20px !important;
  }
`

export const SeeMoreDialogContent = styled(Box)`
  width: 100%;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 20px 20px;
    }
  `}
`

export const SeeMoreDialogTitle = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.green};
`

export const SeeMoreDialogDescription = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: ${COLORS.black};
  max-width: 272px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
    }
  `}
`

export const SeeMoreDialogClose = styled(Box)`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const SeeMoreDialogSubTitle = styled(Box)`
  color: #252525;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
`

export const SeeMoreDialogSubWrapper = styled(Typography)`
  display: flex;
  gap: 5px;

  color: #252525;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 10px;

  .member {
    color: #047e50;
  }
  .guest {
    color: #252525;
  }

  &.comment {
    line-break: anywhere;
  }
`

export const SeeMoreDialogGroupWrapper = styled(Box)``

export const StyledButtonsWrapper = styled(Box)`
  display: flex;
  flex: 1;
  gap: 20px;
  margin-top: 20px;

  button {
    height: 45px;
  }
`

import { AxiosResponse } from 'axios'
import { http } from '@api/RequestApi'

import {
  IMemberPayload,
  IGetMembersResponse,
  IGetMemberResponse
} from '@store/features/member/member.types'

class MemberService {
  private prefix = '/members'

  public async getMember({ id }: { id: string }) {
    return http.get<void, AxiosResponse<IGetMemberResponse>>(`${this.prefix}/${id}/info`)
  }

  public async getMembers(url: string) {
    return http.get<void, AxiosResponse<IGetMembersResponse>>(`${this.prefix}?${url}`)
  }

  public async getMembersExport(url: string) {
    return http.get<void, { data: string }>(`/club${this.prefix}/export${url}`)
  }

  public async getMembersByKey(data: { key: string }) {
    return http.get<void, AxiosResponse<IGetMembersResponse>>(`${this.prefix}?key=${data.key}`)
  }

  public async createMember(data: IMemberPayload) {
    return http.post<IMemberPayload, AxiosResponse<string>>(`${this.prefix}`, data)
  }

  public async uploadCsvMembers({ formData }: { formData: FormData }) {
    return http.post<FormData, AxiosResponse<string>>(`${this.prefix}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public async deleteMember(id: string) {
    return http.delete<void, AxiosResponse<string>>(`${this.prefix}/${id}`)
  }
}

export default MemberService

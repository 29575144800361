import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'
import { Button } from '@components/common'

export const Section = styled(Box)(({ theme }) => ({
  marginTop: '38px',

  '.MuiTypography-root.MuiTypography-h4': {
    marginBottom: '20px'
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '20px',

    '.MuiTypography-root.MuiTypography-h4': {
      marginBottom: '25px'
    }
  }
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  '> .MuiBox-root': {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    button: {
      width: 'min-content',

      '&.itIsForMobile': {
        display: 'none'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    button: {
      fontSize: '13px'
    },
    '> .MuiBox-root': {
      width: '100%',
      display: 'grid',
      gap: '10px',
      gridTemplateColumns: '1fr 1fr',

      button: {
        width: '100%',
        height: '40px',

        '&.itIsForMobile': {
          display: 'block'
        }
      },
      '> *': {
        height: '40px',
        width: '100%',

        button: {
          width: '100%'
        },
        div: {
          width: '100%'
        }
      }
    }
  }
}))

export const FilterItem = styled(Box)<{ active: 'active' | '' }>(({ active }) => ({
  minWidth: 'max-content',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  padding: '11px 16px',
  height: '36px',
  border: active ? `1px solid ${COLORS.black}` : `1px solid ${COLORS.borderGray}`,
  borderRadius: '10px',

  '.MuiTypography-body1': {
    color: active ? COLORS.black : COLORS.blackAlfa
  }
}))

export const FilterItemAlways = styled(Box)<{ active: 'active' | '' }>(({ active }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '11px 16px',
  height: '36px',
  border: active ? `1px solid ${COLORS.black}` : `1px solid ${COLORS.borderGray}`,
  borderRadius: '10px',
  width: '137px',
  fill: active ? COLORS.black : COLORS.borderGray,

  '.MuiTypography-body1': {
    color: active ? COLORS.black : COLORS.blackAlfa
  }
}))

export const StyledButtonForOpenTickets = styled(Button)(({ theme }) => ({
  // display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    width: 'max-content',
    height: '36px',
    fontSize: '13px'
  }
}))

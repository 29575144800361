import { memo } from 'react'

import { Section } from '../../../TicketBox/styled'
import { Loader } from './styled'

const BoxLoader = () => {
  return (
    <Section>
      <Loader />
    </Section>
  )
}

export default memo(BoxLoader)

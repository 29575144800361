import { Box, css, styled } from '@mui/material'
import Button from '@mui/material/Button'

export const StepperButtonsContainer = styled(Box)`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  button {
    max-width: 300px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-top: 0px;
    }
    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
      justify-content: center;

      button {
        max-width: unset;
      }
    }
  `}

  > .MuiBox-root > .MuiBox-root {
    margin-top: auto !important;
  }
`

const StepperButton = styled(Button)`
  padding-left: 37px;
  padding-right: 37px;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.02em;

  .MuiButton-endIcon {
    margin-left: 16px;
  }
  .MuiButton-startIcon {
    margin-right: 16px;
  }
`

export const NextButton = styled(StepperButton)`
  order: 3;
  box-shadow: none;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 1;
      width: 100%;
    }
  `}
`

export const BackButton = styled(StepperButton)`
  order: 1;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 2;
      margin-bottom: 50px;
      width: 100%;
    }
  `}
`

export const FooterLinks = styled(Box)`
  order: 2;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 3;
    }
  `}
`

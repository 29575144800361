import React, { memo } from 'react'

import MuiButton, { ButtonProps } from '@mui/material/Button'
import CircularLoader from '../CircularLoader'

interface IButton extends ButtonProps {
  isLoading?: boolean
  type?: 'submit' | 'button'
  children: string | React.ReactNode
}
const Button = ({ type = 'button', children, isLoading = false, ...rest }: IButton) => {
  return (
    <MuiButton type={type} disableRipple {...rest}>
      {isLoading ? <CircularLoader width={25} height={25} /> : children}
    </MuiButton>
  )
}

export default memo(Button)

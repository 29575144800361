import { FC } from 'react'

import { StyledPageTitle } from '@ui/PageTitle/styled'
import { TypographyProps } from '@mui/material'

const PageTitle: FC<TypographyProps> = ({ children, ...props }) => {
  return <StyledPageTitle {...props}>{children}</StyledPageTitle>
}

export default PageTitle

/* eslint-disable no-restricted-imports */

import { Close } from '@mui/icons-material'
import { getDateFromFirstGroup } from '@utils/time'
import { FiltersStatusesEnum, TicketCounterStatuses } from '@appTypes/enums/filters'
import useTicketCounterHook from './useSeeMoreHook'
import { TicketButtonsWrapper, MembersAndGroups } from './components'
import {
  SeeMoreDialogClose,
  SeeMoreDialogContainer,
  SeeMoreDialogContent,
  SeeMoreDialogSubTitle,
  SeeMoreDialogSubWrapper
} from './styled'

const SeeMoreDialog = () => {
  const page = window.location.pathname
  const { ticket, handleCancel } = useTicketCounterHook()

  if (ticket === null) {
    return null
  }

  const date = getDateFromFirstGroup(ticket.groups)

  const approvedCourse = ticket.ticketCourse

  const renderCourses = () => {
    if (
      ticket.status === FiltersStatusesEnum.APPROVED ||
      ticket.offerStatus === TicketCounterStatuses.PENDING
    ) {
      return <span>{approvedCourse?.course?.name}</span>
    }

    return ticket?.ticketCourses?.map(({ id, course }, index) => (
      <span key={id}>
        {course?.name}
        {ticket?.ticketCourses.length === index + 1 ? '' : ', '}
      </span>
    ))
  }

  return (
    <SeeMoreDialogContainer onClose={handleCancel} open={!!ticket}>
      <SeeMoreDialogContent>
        <SeeMoreDialogSubTitle>Date and Time</SeeMoreDialogSubTitle>
        <SeeMoreDialogSubWrapper>
          {date.format('MMM D, YYYY (dddd)')} {date.format('hh:mm A')}
          {' - '}
          {date.add(ticket.availability, 'minutes').format('hh:mm A')}
        </SeeMoreDialogSubWrapper>
        <SeeMoreDialogSubTitle>Courses</SeeMoreDialogSubTitle>
        <SeeMoreDialogSubWrapper>{renderCourses()}</SeeMoreDialogSubWrapper>
        <MembersAndGroups ticket={ticket} />

        {ticket.comment ? (
          <>
            <SeeMoreDialogSubTitle>Comment</SeeMoreDialogSubTitle>
            <SeeMoreDialogSubWrapper className='comment'>{ticket.comment}</SeeMoreDialogSubWrapper>
          </>
        ) : null}

        <SeeMoreDialogClose onClick={handleCancel}>
          <Close fontSize='large' cursor='pointer' />
        </SeeMoreDialogClose>
        {page === '/open-tickets' && ticket.offerStatus !== TicketCounterStatuses.PENDING ? (
          <TicketButtonsWrapper ticket={ticket} />
        ) : null}
      </SeeMoreDialogContent>
    </SeeMoreDialogContainer>
  )
}

export default SeeMoreDialog

import { memo } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import {
  FiltersParamsKeysEnum,
  FiltersOrderValuesEnum,
  FiltersPropsTitleEnum
} from '@appTypes/enums/filters'

import useHandleFilters from './useHandleFilters'
import { FilterButtonsWrapper, SimpleFiltersSwitcher } from './components'
import { Section, Wrapper } from './styled'
import SimpleButtonsSwitcher from './components/SimpleButtonsSwitcher'
import { IFiltersOrders } from './data'

interface IFilters {
  total?: number
  title: FiltersPropsTitleEnum
  page: PAGE_ROUTES_PRIVATE
  placeholder: string
  filtersOrders: IFiltersOrders
}

const Filters = ({ filtersOrders, page, total, title, placeholder }: IFilters) => {
  const {
    members,
    selected,
    inputValue,
    searchByKey,
    setSelected,
    setInputValue,
    setSearchByKey,
    handleSelectChange
  } = useHandleFilters()

  return (
    <Section>
      <Typography component='h4' variant='h4'>
        {title}
      </Typography>
      <Wrapper>
        <Box className='for-courses-and-members'>
          <SimpleFiltersSwitcher
            total={total || 0}
            filtersOrders={filtersOrders}
            page={page}
            members={members}
            selected={selected}
            inputValue={inputValue}
            searchByKey={searchByKey}
            placeholder={placeholder}
            setInputValue={setInputValue}
            setSelected={setSelected}
            setSearchByKey={setSearchByKey}
            handleSelectChange={handleSelectChange}
          />
        </Box>
        <SimpleButtonsSwitcher total={total} />
      </Wrapper>
    </Section>
  )
}

export default memo(Filters)

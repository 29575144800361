import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gridTemplateRows: 'max-content',
  width: '100%',
  padding: '20px',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '10px',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray87}`,

  '.MuiTypography-root': {
    gridColumn: '1 / 3'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.green,
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '18px',
  marginBottom: '20px',

  [theme.breakpoints.down('md')]: {
    fontSize: '13px'
  }
}))

export const SubTitles = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '16px',

  [theme.breakpoints.down('md')]: {
    marginBottom: '7px'
  }
}))

export const StyledDoubleBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridColumn: '1 / 3',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    gap: '15px',
    flexDirection: 'column'
  }
}))

export const StyledAddressBox = styled(Box)(({ theme }) => ({
  gridColumn: '1 / 3',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

export const StyledNoteBox = styled(Box)(() => ({
  gridColumn: '1 / 3'
}))

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  gridColumn: '1 / 3',
  '.MuiButtonBase-root': {
    width: 'min-content',
    marginLeft: '8px'
  },

  'a > .MuiButtonBase-root': {
    marginLeft: '0px'
  },
  padding: '0px 20px',
  width: 'calc(100% + 40px)',
  marginLeft: '-20px',
  borderTop: '1px solid #d7d7d7',
  paddingTop: '20px',

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '1fr 1fr 1fr',

    '.MuiButtonBase-root': {
      width: 'min-content',
      marginLeft: '0px'
    },

    '> a > button ': {
      width: '100% !important'
    },
    '>  *': {
      width: '100% !important'
    }
  }
}))

export const ValueContainer = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const NoteValueContainer = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  word-break: break-word;
  -webkit-box-orient: vertical;
`

export const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  marginBottom: '15px',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  }
}))

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  l: 1025, // large
  lg: 1200,
  xl: 1360,
  el: 1441, // Extra Large
  eel: 1921 // Extra-Extra Large
}

import { ValidationKeysEnum } from '@appTypes/enums/global'
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  MEMBER_CREATE_REGEX
} from '@constants/validation'
import { ValidationMessages } from './messages'

export const validation = {
  [ValidationKeysEnum.LOGIN]: {
    email: {
      required: {
        value: true,
        message: ValidationMessages.login.email.required
      },
      pattern: {
        value: EMAIL_REGEX,
        message: ValidationMessages.login.email.pattern
      }
    },
    password: {
      required: {
        value: true,
        message: ValidationMessages.login.password.required
      },
      pattern: {
        value: PASSWORD_REGEX,
        message: ValidationMessages.login.password.pattern
      }
    }
  },
  [ValidationKeysEnum.FORGOT]: {
    email: {
      required: {
        value: true,
        message: ValidationMessages.forgot.required
      },
      pattern: {
        value: EMAIL_REGEX,
        message: ValidationMessages.forgot.email
      }
    }
  },
  [ValidationKeysEnum.RECOVER_PASSWORD]: {
    newPassword: {
      required: {
        value: true,
        message: ValidationMessages.login.password.required
      },
      pattern: {
        value: PASSWORD_REGEX,
        message: ValidationMessages.login.password.pattern
      }
    },
    confirmPassword: {
      required: {
        value: true,
        message: ValidationMessages.login.password.required
      },
      validate: (
        _: any,
        { confirmPassword, newPassword }: { confirmPassword: string; newPassword: string }
      ) => {
        return confirmPassword === newPassword ? true : 'The Password does not match'
      }
    }
  },
  [ValidationKeysEnum.CREATE_COURSE]: {
    courseName: {
      required: {
        value: true,
        message: ValidationMessages.createCourse.required
      }
    },
    proShopPhoneNumber: {
      required: {
        value: false,
        message: ValidationMessages.createCourse.required
      },
      pattern: {
        // eslint-disable-next-line max-len
        value: PHONE_REGEX,
        message: ValidationMessages.createCourse.proShopPhoneNumber
      }
    },
    courseAddress: {
      required: {
        value: true,
        message: ValidationMessages.createCourse.required
      }
    },
    emailAddress: {
      required: {
        value: true,
        message: ValidationMessages.createCourse.required
      },
      pattern: {
        value: EMAIL_REGEX,
        message: ValidationMessages.createCourse.emailAddress
      }
    },
    memberFee: {
      required: {
        value: true,
        message: ValidationMessages.createCourse.required
      },
      pattern: {
        value: /^\d+$/,
        message: ValidationMessages.createCourse.numeric
      }
    },
    guestFee: {
      required: {
        value: true,
        message: ValidationMessages.createCourse.required
      },
      pattern: {
        value: /^\d+$/,
        message: ValidationMessages.createCourse.numeric
      }
    },
    courseNotes: {
      required: {
        value: false,
        message: ValidationMessages.createCourse.required
      },
      maxLength: {
        value: 300,
        message: ValidationMessages.createCourse.maxLength
      }
    }
  },
  [ValidationKeysEnum.CREATE_MEMBER]: {
    name: {
      required: {
        value: true,
        message: ValidationMessages.createMember.required
      }
    },
    phone: {
      required: {
        value: false,
        message: ValidationMessages.createMember.required
      },
      // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
      pattern: {
        value: PHONE_REGEX,
        message: ValidationMessages.createMember.phone
      }
    },
    email: {
      required: {
        value: true,
        message: ValidationMessages.createMember.required
      },
      pattern: {
        value: EMAIL_REGEX,
        message: ValidationMessages.createMember.email
      }
    },
    password: {
      required: {
        value: true,
        message: ValidationMessages.createMember.required
      },
      pattern: {
        value: MEMBER_CREATE_REGEX,
        message: ValidationMessages.createMember.password
      }
    },
    number: {
      required: {
        value: true,
        message: ValidationMessages.createMember.required
      }
    }
  },
  [ValidationKeysEnum.CHANGE_PASSWORD]: {
    newPassword: {
      required: {
        value: true,
        message: ValidationMessages.login.password.required
      },
      pattern: {
        value: PASSWORD_REGEX,
        message: ValidationMessages.login.password.pattern
      }
    },
    confirmNewPassword: {
      required: {
        value: true,
        message: ValidationMessages.login.password.required
      },
      validate: (
        _: any,
        { confirmNewPassword, newPassword }: { confirmNewPassword: string; newPassword: string }
      ) => {
        return confirmNewPassword === newPassword ? true : 'The Password does not match'
      }
    }
  }
}

export default validation

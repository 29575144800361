import Swal, { SweetAlertOptions } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SweetAlert = withReactContent(Swal)

const useAlert = () => {
  const showSuccess = async (text: string, options?: SweetAlertOptions) => {
    return SweetAlert.fire({
      text,
      title: 'Success',
      icon: 'success',
      ...options
    })
  }

  const showError = async (text: string, options?: SweetAlertOptions) => {
    return SweetAlert.fire({
      text,
      title: 'Error',
      icon: 'error',
      ...options
    })
  }

  const showWarning = async (text: string, options?: SweetAlertOptions) => {
    return SweetAlert.fire({
      text,
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      ...options
    })
  }

  const showToast = async (text: string, options?: SweetAlertOptions) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    return Toast.fire({
      text,
      icon: 'success',
      title: 'Success',
      ...options
    })
  }

  const showSuccessToast = async (text: string, options?: SweetAlertOptions) =>
    showToast(text, options)

  const showErrorToast = async (text: string, options?: SweetAlertOptions) => {
    return showToast(text, {
      icon: 'error',
      title: 'Error',
      ...options
    })
  }

  return { showSuccess, showError, showWarning, showSuccessToast, showErrorToast }
}

export default useAlert

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '247px',
  width: '100%',
  padding: '20px',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '10px',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray87}`
}))

export const Title = styled(Typography)(() => ({
  color: COLORS.green,
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '18px'
}))

export const SubTitles = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '16px',
  marginBottom: '7px'
}))

export const StyledDoubleBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridColumn: '1 / 3',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const StyledAddressBox = styled(Box)(() => ({}))

export const StyledNoteBox = styled(Box)(() => ({}))

export const ButtonsContainer = styled(Box)(() => ({
  gridColumn: '1 / 3',
  borderTop: '1px solid #dedede',
  padding: '20px 20px 0px 20px',
  width: 'calc(100% + 40px)',
  marginLeft: '-20px',

  '.MuiButtonBase-root': {
    width: 'min-content'
  }
}))

export const ValueContainer = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const ContentWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: '15px',
  gridTemplateColumns: '1fr 1fr'
}))

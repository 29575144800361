import { AxiosResponse } from 'axios'
import { http } from '@api/RequestApi'

import { IUserModelBackendResponse } from '@appTypes/model/user.model'

import {
  ILoginResponse,
  ILoginPayload,
  IResetPassword,
  ISetPasswordPayload,
  IChangePassword
} from '@store/features/auth/auth.types'

class AuthService {
  private prefix = '/auth'

  public async login(data: ILoginPayload) {
    return http.post<ILoginPayload, AxiosResponse<ILoginResponse>>(`${this.prefix}/login`, data)
  }

  public async getProfile() {
    return http.get<void, AxiosResponse<IUserModelBackendResponse>>(`${this.prefix}/profile`)
  }

  public async logout() {
    return http.post(`${this.prefix}/logOut`)
  }

  public async resetPassword(data: IResetPassword) {
    return http.post(`${this.prefix}/password-reset`, data)
  }

  public async setPassword(data: ISetPasswordPayload) {
    return http.put(`${this.prefix}/password-set`, data)
  }

  public async changePassword(data: IChangePassword) {
    return http.put(`${this.prefix}/password`, data)
  }
}

export default AuthService

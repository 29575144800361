import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { useLocation } from 'react-router-dom'
import FilterButtonsWrapper from '../FilterButtonsWrapper'

interface ISimpleButtonsSwitcher {
  total?: number
}

const SimpleButtonsSwitcher = ({ total }: ISimpleButtonsSwitcher) => {
  const location = useLocation()

  switch (location.pathname) {
    case PAGE_ROUTES_PRIVATE.TICKET_HISTORY:
      return <FilterButtonsWrapper total={total} />
    default:
      return null
  }
}

export default SimpleButtonsSwitcher

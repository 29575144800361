import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'

import Container from '@mui/material/Container'
import { RouteEnum } from '@appTypes/enums/global'
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages'
import HamburgerIcon from '@assets/icons/hamburgerIcon.svg'

import { Box } from '@mui/material'
import { useAppSelector } from '@store/hooks'
import { headerPages } from '@collections/routes'
import { Logo, LinkTo } from '@components/common'
import { SelectOption, SelectSimple } from './components'
import { PagesBox, LogoWrapper, StyledAppBar, StyledToolBar, HamburgerIconWrapper } from './styled'

interface IHeader {
  type: RouteEnum
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

function Header({ setIsOpen, type }: IHeader) {
  const [activePage, setActivePage] = useState<string>('')
  const user = useAppSelector((state) => state.auth.user)
  const location = useLocation()

  useEffect(() => {
    headerPages.find((item) => {
      if (item.link === location.pathname) {
        setActivePage(item.link)
        return item
      }

      const child = item?.options?.find((i) => {
        if (i.link === location.pathname) {
          return i
        }
      })

      if (child) {
        setActivePage(child?.link)
      }
    })
  }, [location.pathname])

  return (
    <StyledAppBar>
      <Container maxWidth='xl'>
        <StyledToolBar>
          <Box>
            <LogoWrapper type={type}>
              <LinkTo href={user === null ? PAGE_ROUTES_PUBLIC.SIGN_IN : PAGE_ROUTES_PRIVATE.HOME}>
                <Logo />
              </LinkTo>
            </LogoWrapper>
          </Box>

          {user ? (
            <HamburgerIconWrapper
              onClick={() => {
                setIsOpen && setIsOpen((prev) => !prev)
              }}
            >
              <img src={HamburgerIcon} alt='Hamburger Icon' loading='lazy' />
            </HamburgerIconWrapper>
          ) : null}

          {user === null ? null : (
            <PagesBox>
              {headerPages.map(({ id, link, title, params, options }) => {
                if (link === PAGE_ROUTES_PRIVATE.CHANGE_PASSWORD) {
                  return null
                }

                if (!options?.length) {
                  return (
                    <SelectSimple
                      key={id}
                      id={id}
                      link={link}
                      title={title}
                      params={params}
                      activePage={activePage}
                    />
                  )
                }

                return (
                  <SelectOption
                    id={id}
                    key={id}
                    title={title}
                    options={options}
                    activePage={activePage}
                  />
                )
              })}
            </PagesBox>
          )}
        </StyledToolBar>
      </Container>
    </StyledAppBar>
  )
}
export default memo(Header)

import { useEffect, useState } from 'react'

import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'
import { ticketUpdateStatusThunk } from '@store/features/ticket/ticket.actions'

import CloseSvg from '@svgs/close.svg'
import { Button, FormHelperText } from '@components/common'
import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { Section, StyledIcon, StyledTypography } from './styled'

const DialogAlertTicketDeclineVia = () => {
  const dispatch = useAppDispatch()
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { popUpId, status } = useAppSelector((state) => state.general.popUp)

  const handleDecline = () => {
    setMessage('')
    setIsLoading(true)

    if (!popUpId) {
      return false
    }

    const payload = {
      status: FiltersStatusesEnum.DECLINED
    }

    dispatch(
      ticketUpdateStatusThunk({
        ticketId: popUpId,
        payload
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setPopUpData({ status: true }))
      })
      .catch(() => {
        setMessage('Something went wrong')
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (!status) {
      setIsLoading(false)
    }
  }, [status])

  const handleCancel = () => {
    dispatch(setPopUpData({ popUpType: null, popUpId: null, status: false }))
  }

  return (
    <Section>
      <StyledIcon src={CloseSvg} alt='close icon' onClick={handleCancel} />
      <Typography component='h4' variant='h4' align='center'>
        Decline Ticket
      </Typography>
      <StyledTypography>Are you sure you want to reject this ticket?</StyledTypography>

      <DialogActions>
        <Button
          type='button'
          variant='contained'
          color='secondary'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleDecline}
        >
          Decline
        </Button>
        <Button type='button' color='primary' variant='outlined' onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
      <FormHelperText elseError={message} />
    </Section>
  )
}

export default DialogAlertTicketDeclineVia

import { useState, useMemo } from 'react'

import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { dayJsWrap } from '@utils/time'
import { Wrapper } from './styled'

interface ITimePicker {
  date: string
  setState: (iso: string) => void
}

const TimePicker = ({ date, setState }: ITimePicker) => {
  const dayJs = useMemo(() => dayJsWrap(date), [])

  const [value, setValue] = useState(dayJs)
  const [isError, setIsError] = useState<string | null>(null)

  const setSelectedTeeTimeHandler = () => {}

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DesktopTimePicker']}>
          <DesktopTimePicker
            autoFocus
            value={value}
            label='Start Time'
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue !== null) {
                setState(newValue.toISOString())
              }
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Wrapper>
  )
}

export default TimePicker

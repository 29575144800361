import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Content = styled(Box)<{ empty: string }>(({ theme, empty }) => {
  const flex = {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20vh'
  }

  const grid = {
    marginTop: '40px',
    display: 'grid',
    gridGap: '40px',
    gridTemplateColumns: '1fr 1fr 1fr',

    [theme.breakpoints.down('md')]: {
      gridGap: '20px'
    }
  }

  const mixed = empty === 'empty' ? flex : grid

  if (empty === 'empty') {
    return { ...flex }
  }

  return {
    '.infinite-scroll-component__outerdiv': {
      '.infinite-scroll-component': {
        ...mixed,
        [theme.breakpoints.down('xl')]: {
          gridTemplateColumns: '1fr 1fr',
          gridGap: '20px',
          justifyContent: 'center'
        },
        [theme.breakpoints.down('lg')]: {
          gridTemplateColumns: '1fr 1fr',
          justifyItems: 'center'
        },
        [theme.breakpoints.down('l')]: {
          gridTemplateColumns: '1fr',
          justifyItems: 'center'
        },
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
          justifyItems: 'center'
        }
      }
    }
  }
})

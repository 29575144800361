import { memo } from 'react'

import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

import CloseSvg from '@svgs/close.svg'
import { Button, CircularLoader, FormHelperText, Select } from '@components/common'
import useTicketCounterHook from './useTicketCounterHook'
import { TimePicker } from './components'
import {
  Section,
  StyledIcon,
  StyledTypography,
  DateWrapper,
  StyledBoldTypography,
  ItemWrapper
} from './styled'

const DialogAlertTicketCounterVia = () => {
  const {
    ticket,
    message,
    courses,
    executionStatus,
    isLoading,
    dayJsDate,
    selectedCourse,
    setState,
    handleCancel,
    handleUpdate
  } = useTicketCounterHook()

  if (isLoading) {
    return (
      <Section>
        <CircularLoader width={25} height={25} />
      </Section>
    )
  }

  if (!courses?.length || ticket.date === '') {
    return (
      <Section>
        <StyledIcon src={CloseSvg} alt='close icon' onClick={handleCancel} />
        <Typography component='h4' variant='h4' align='center'>
          {executionStatus || 'There is no full information about this ticket'}
        </Typography>
      </Section>
    )
  }

  return (
    <Section>
      <StyledIcon src={CloseSvg} alt='close icon' onClick={handleCancel} />
      <Typography component='h4' variant='h4' align='center'>
        Change the Time
      </Typography>
      <StyledTypography>Choose a free date and time to book.</StyledTypography>
      <DateWrapper>
        <StyledBoldTypography>Date</StyledBoldTypography>
        <Typography>{dayJsDate.format('MMM DD, YYYY')}</Typography>
      </DateWrapper>
      <ItemWrapper>
        <TimePicker
          date={ticket.date}
          setState={(iso: string) => {
            setState((prev) => ({ ...prev, ticket: { ...prev.ticket, date: iso } }))
          }}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Select
          title='Courses'
          options={courses}
          selectedOption={selectedCourse}
          setState={(course: { value: string; name: string }) => {
            setState((prev) => ({ ...prev, selectedCourse: course }))
          }}
        />
      </ItemWrapper>
      <DialogActions>
        <Button
          type='button'
          variant='contained'
          color='primary'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleUpdate}
        >
          Edit
        </Button>
        <Button type='button' color='primary' variant='outlined' onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
      <FormHelperText elseError={message} />
    </Section>
  )
}

export default memo(DialogAlertTicketCounterVia)

import { IDefaultFilterByDate, TypeEnum } from '@components/DropDownFilterByDate/types'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import { Box } from '@mui/material'
import { FromToWrapper } from '../../../../styled'

interface IFromTo extends IDefaultFilterByDate {}

const FromTo = ({ isCalendar, setIsCalendar }: IFromTo) => {
  return (
    <FromToWrapper>
      <Box
        onClick={() => {
          setIsCalendar((prev) => ({ ...prev, isOpen: true, type: TypeEnum.FROM }))
        }}
      >
        {isCalendar.values.dateFrom ? (
          isCalendar.values.dateFrom.format('YYYY/MM/DD')
        ) : (
          <span>
            <span className='hideForMobile'>Date</span> From
          </span>
        )}
        <CalendarMonthIcon />
      </Box>
      <Box
        onClick={() => {
          setIsCalendar((prev) => ({ ...prev, isOpen: true, type: TypeEnum.TO }))
        }}
      >
        {isCalendar.values.dateTo ? (
          isCalendar.values.dateTo.format('YYYY/MM/DD')
        ) : (
          <span>
            <span className='hideForMobile'>Date</span> To
          </span>
        )}
        <CalendarMonthIcon />
      </Box>
    </FromToWrapper>
  )
}

export default FromTo

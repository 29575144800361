import { ValidationKeysEnum } from '@appTypes/enums/global'

export const ValidationMessages = {
  [ValidationKeysEnum.CREATE_MEMBER]: {
    required: 'The Field is mandatory to fill in.',
    phone: 'The Phone is incorrect, please try again.',
    email: 'The Email is incorrect, please try again.',
    password:
      'The Password must contain min 6-30 symbols: one uppercase, one lowercase, a number and a special character.'
  },
  [ValidationKeysEnum.CREATE_COURSE]: {
    required: 'The field is required to be filled in.',
    proShopPhoneNumber: 'The phone is incorrect.',
    emailAddress: 'The Email address is incorrect, please try again.',
    numeric: 'The field must contain only numbers',
    maxLength: 'The note cannot have more than 300 symbols.'
  },
  [ValidationKeysEnum.FORGOT]: {
    required: 'Please enter your Email',
    email: 'The Email address is incorrect, please try again.'
  },
  [ValidationKeysEnum.LOGIN]: {
    email: {
      required: 'Please enter your Email',
      pattern: 'The Email address is incorrect, please try again.'
    },
    password: {
      required: 'Please enter your Password',
      pattern: 'The Password is incorrect, please try again.'
    }
  }
}

import { Box, css, styled } from '@mui/material'
import COLORS from '@app/colors'

export const ConfirmStepContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Inter', serif;
  font-weight: 500;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 40px;
    }
  `}
`

export const Content = styled(Box)`
  background: #ffffff;
  border: 1px solid ${COLORS.black};
  border-radius: 10px;
  width: 534px;
  margin: 60px auto 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 50px 0;
      font-size: 13px;
    }
  `}
`

export const ContentBlock = styled(Box)`
  padding: 20px;
`

export const CommentWrapper = styled(Box)`
  word-wrap: break-word;
`

export const ContentTitle = styled(Box)`
  color: #00000080;
  margin-bottom: 10px;
`

export const CourseInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: start;
    }
  `}
`

export const Text = styled('span')<{ color?: string | boolean }>`
  color: ${({ color }) => color || COLORS.black};
`

export const DateInfo = styled(Box)`
  color: ${COLORS.black};
  display: flex;
  align-items: center;
  gap: 5px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: start;
    }
  `}
`
export const MembersList = styled(Box)`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: start;
    }
  `}
`

export const MemberName = styled('span')<{ green?: boolean }>`
  color: ${({ green }) => (green ? COLORS.green : COLORS.black)};
`

export const Divider = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.16);
`

export const GroupItem = styled(Box)`
  margin-top: 20px;

  .group-name {
    color: rgba(0, 0, 0, 0.5);
  }
`

import { COLORS } from '@collections/colors'

const MuiAutocomplete = {
  styleOverrides: {
    root: {
      border: `1px solid ${COLORS.borderGray}`,
      borderRadius: '10px',

      '>.MuiFormControl-root': {
        maxHeight: 'inherit',
        '>.MuiInputBase-root': {
          padding: '5px 9px',
          maxHeight: 'inherit',

          '>.MuiInputBase-input': {
            padding: '0',
            background: COLORS.white,
            border: 'none',
            borderRadius: 0,
            height: 'auto',

            '&:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 100px ${COLORS.white} inset`
            }
          },
          fieldset: {
            border: 'none'
          }
        }
      },

      '&.MuiAutocomplete-root': {
        maxWidth: '450px',
        width: '100%',

        '.MuiFormControl-root': {
          width: '100%',
          height: '50px',
          borderRadius: '10px',

          '> .MuiInputBase-root': {
            padding: '0',
            borderRadius: 10,

            '.MuiInputBase-input': {
              // padding: '10px',
              height: '50px',
              borderRadius: '10px',
              padding: '10px 25px 10px 10px !important'
            }
          }
        }
      }
    }
  }
}

export default MuiAutocomplete

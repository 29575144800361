import { ValidationKeysEnum } from '@appTypes/enums/global'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import validation from '../collections/validation'

interface IInputDataGenerator<T extends FieldValues> {
  register: UseFormRegister<T>
  validationKey: ValidationKeysEnum
  array: { label: string; name: string; placeholder: string; type: string }[]
}

export const inputDataGenerator = <T extends FieldValues>({
  array,
  register,
  validationKey
}: IInputDataGenerator<T>) => {
  const info = validation[validationKey as keyof typeof validation]

  return array.map(({ label, name, placeholder, type }) => {
    return {
      type,
      label,
      id: name,
      key: name,
      placeholder,
      ...register(name as any, info[name as keyof typeof info])
    }
  })
}

export const COLORS = {
  black: '#000',
  gray: '#E5E5E5',
  blackAlfa: 'rgba(0, 0, 0, 0.5)',
  grayUnderline: 'rgba(32, 31, 33, 0.5)',
  grayButton: 'rgba(32, 31, 33, 0.3)',
  gray87: '#dedede',
  error: '#dc3545',
  white: '#FFFFFF',
  raisinBlack: '#252525',
  raisinBlack2: '#201F21',
  green: '#047E50',
  red: '#EB5757',
  disabled: '#9b9b9b',
  borderGray: '#d9d9d9',
  bordersInHeader: '#F2F2F2',
  borderBottomInSelect: '#CECECE'
}

export type ColorsType = typeof COLORS

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Select } from '@components/common'

export const Section = styled(Box)(({ theme }) => ({
  padding: '100px 86px',
  position: 'relative',
  width: '600px',
  textAlign: 'center',

  '.MuiFormHelperText-root': {
    textAlign: 'center'
  },

  '.css-1v8igmu-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    width: '100%',
    textAlign: 'left'
  },
  '.MuiDialogActions-root': {
    padding: '10px 0px'
  },
  [theme.breakpoints.down('md')]: {
    padding: '30px 20px 10px',
    width: '100%',
    maxWidth: '600px'
  }
}))

export const StyledIcon = styled('img')(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer'
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: '10px 0 40px 0',

  [theme.breakpoints.down('md')]: {
    margin: '10px 0 20px 0'
  }
}))

export const StyledBoldTypography = styled(Typography)(() => ({
  fontWeight: 'bold'
}))

export const CourseSelect = styled(Select)(() => ({
  display: 'none'
}))

export const DateWrapper = styled(Box)(() => ({
  textAlign: 'left',
  marginBottom: '20px'
}))

export const ItemWrapper = styled(Box)(() => ({
  marginBottom: '20px'
}))

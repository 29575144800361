import { css } from '@mui/material/styles'

import { FontFamilyNames, ThemeFont } from '@appTypes/fonts'

import InterExtraBoldTtf from './Inter-ExtraBold.ttf'
import InterBoldTtf from './Inter-Bold.ttf'
import InterTtf from './Inter-Medium.ttf'
import InterLightTtf from './Inter-Light.ttf'

const InterFont: ThemeFont = {
  fontFamily: [
    FontFamilyNames.InterExtraBold,
    FontFamilyNames.InterBold,
    FontFamilyNames.Inter,
    FontFamilyNames.InterLight
  ],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyNames.InterExtraBold};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.InterExtraBold}), url(${InterExtraBoldTtf}) format('truetype');
    }
    @font-face {
      font-family: ${FontFamilyNames.InterBold};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.InterBold}), url(${InterBoldTtf}) format('truetype');
    }
    @font-face {
      font-family: ${FontFamilyNames.Inter};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.Inter}), url(${InterTtf}) format('truetype');
    }
    @font-face {
      font-family: ${FontFamilyNames.InterLight};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.InterLight}), url(${InterLightTtf}) format('truetype');
    }
  `
}

export default InterFont

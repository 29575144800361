import { css, FormHelperText, InputLabel, Select, styled, Typography } from '@mui/material'
import COLORS from '@app/colors'

export const StyledSelect = styled(Select)`
  border-radius: 10px;
  background: white;
`

export const StyledSelectLabel = styled(InputLabel)`
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${COLORS.black};
  opacity: 0.5;
`

export const StyledSelectTitle = styled(Typography)`
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${COLORS.black};
  margin-bottom: 10px;
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-weight: 400;
    }
  `}
`

export const StyledHelperText = styled(FormHelperText)`
  margin-right: 0;
`

import { memo, useMemo } from 'react'
import { LinkTo } from '@components/common'
import { COLORS } from '@collections/colors'
import { Select as MuiSelect, MenuItem } from '@mui/material'
import { logoutThunk } from '@store/features/auth/auth.actions'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { useNavigate } from 'react-router-dom'

interface ISelectOption {
  activePage: string
  id: string
  title: string
  options: {
    params: string
    title: string
    link: string
  }[]
}

const SelectOption = ({ activePage, id, title, options }: ISelectOption) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.auth.user)

  const styles = useMemo(() => {
    return {
      padding: 0,
      height: '61px',
      display: 'flex',
      textAlign: 'center',
      background: 'white',
      justifyContent: 'center',
      borderBottom: `1px solid ${COLORS.borderBottomInSelect}`,
      ':last-child': {
        borderBottom: 'none'
      },
      ':focus': {
        background: 'white'
      },
      '&:hover': {
        background: 'white'
      }
    }
  }, [])

  const specialCase = title === 'username'
  const owner = user?.club?.owner
  const unChangeableValue = specialCase ? owner : title

  return (
    <MuiSelect
      key={id}
      value={unChangeableValue}
      defaultValue={unChangeableValue}
      placeholder={unChangeableValue}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>Placeholder</em>
        }

        return selected
      }}
    >
      {options?.map(({ title: optionTitle, link: optionLink, params: optionParams }) => {
        return (
          <MenuItem
            key={optionTitle}
            sx={styles}
            onClick={() => {
              if (optionTitle === 'Log Out') {
                dispatch(logoutThunk())
              } else if (optionTitle === 'Change Password') {
                navigate(`${PAGE_ROUTES_PRIVATE.CHANGE_PASSWORD}`)
              }
            }}
          >
            <LinkTo href={optionLink + optionParams} key={optionTitle}>
              {activePage === optionLink ? <b>{optionTitle}</b> : optionTitle}
            </LinkTo>
          </MenuItem>
        )
      })}
    </MuiSelect>
  )
}

export default memo(SelectOption)

import { css } from '@mui/material/styles'

import { FontFamilyNames, ThemeFont } from '@appTypes/fonts'

import SourceSerifPro from './SourceSerifPro-Bold.ttf'

const SourceSerifFonts: ThemeFont = {
  fontFamily: [FontFamilyNames.SourceSerifPro],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyNames.SourceSerifPro};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.SourceSerifPro}), url(${SourceSerifPro}) format('truetype');
    }
  `
}

export default SourceSerifFonts

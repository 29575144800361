import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'

import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { TimeZone } from '@constants/time'
import { IGroup } from '@appTypes/global'
import { dayJsTz } from './dayjs'

interface ICollectDate {
  date: string
  endTime: string
  startTime: string
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(TimeZone)
dayjs.extend(relativeTime)

export const dayJsWrap = (iso: string) => {
  return dayjs(iso).tz(TimeZone)
}

export const generateEndDateByAvailability = (
  DateISO: string,
  availability: number
): dayjs.Dayjs => {
  return dayJsWrap(DateISO).add(availability, 'minute')
}

export const reformatDate = (dayjs: dayjs.Dayjs): string => {
  return dayjs.format('MM/DD/YYYY').replaceAll('/', '.')
}

export const reformatTime = (dayjs: dayjs.Dayjs): string => {
  return dayjs.format('hh:mm A')
}

export const collectDate = ({ date, startTime, endTime }: ICollectDate): React.ReactNode => {
  return (
    <span>
      {date}
      <br />
      <span>
        {startTime} {endTime ? `- ${endTime}` : ''}
      </span>
    </span>
  )
}

interface IFormatDate {
  date: string
  availability: number
  status?: FiltersStatusesEnum
  offerStatus?: string
  one?: boolean
}

export const formatDate = ({
  date: DateISO,
  status,
  availability,
  offerStatus,
  one
}: IFormatDate) => {
  const dateJs = dayJsWrap(DateISO)

  if (one || offerStatus === 'PENDING' || FiltersStatusesEnum.APPROVED === status) {
    return collectDate({
      date: reformatDate(dateJs),
      startTime: reformatTime(dateJs),
      endTime: ''
    })
  }
  const end = generateEndDateByAvailability(DateISO, availability)

  return collectDate({
    date: reformatDate(dateJs),
    startTime: reformatTime(dateJs),
    endTime: reformatTime(end)
  })
}

export const hourToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return {
    hours,
    minutes
  }
}

const getDefaultDate = () => {
  // Provide a default date or implement your own default date logic
  return dayJsTz(TimeZone, '2000-01-01')
}

export const getDateFromFirstGroup = (groups: IGroup[]) => {
  try {
    if (groups && groups[0] && groups[0].date) {
      return dayJsTz(TimeZone, groups[0].date)
    }

    throw new Error('Invalid date format in ticket groups')
  } catch (error: any) {
    console.error('Error parsing date:', error?.message)

    // Handle the error as needed, e.g., return a default date or rethrow the error
    return getDefaultDate()
  }
}

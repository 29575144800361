import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'

import PageTitle from '@ui/PageTitle'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import PageSubtitle from '@ui/PageSubtitle'

import { saveCommentDataInStorage } from '../../../../helpers/enterCommentHelpers'
import StepperButtons from '../StepperButtons'
import {
  EnterCommentContainer,
  EnterCommentContent,
  TextArea,
  TextAreaBox,
  TextAreaTitle,
  TextMessage
} from './styled'
import { IEnterCommentFormValues } from '../../../../types'

interface EnterCommentStepProps {
  defaultValues: IEnterCommentFormValues
  onNext: (data: IEnterCommentFormValues) => void
  onBack: () => void
}

const EnterCommentStep: FC<EnterCommentStepProps> = ({ onNext, onBack, defaultValues }) => {
  const [validationMessage, setValidationMessage] = useState<string | null>(null)
  const { handleSubmit, control, setValue } = useForm<IEnterCommentFormValues>({
    defaultValues
  })

  const { text } = useWatch({
    control
  })

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    if (target.value.length > 100) {
      validationMessage === null &&
        setValidationMessage('The comment cannot have more than 100 symbols.')
    } else {
      validationMessage && setValidationMessage(null)
      setValue('text', target.value)
    }
  }

  const submitHandler: SubmitHandler<IEnterCommentFormValues> = (data) => {
    onNext(data)
  }

  useEffect(() => {
    saveCommentDataInStorage({ text: text || '' })
  }, [text])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <EnterCommentContainer component='form' onSubmit={handleSubmit(submitHandler)}>
      <EnterCommentContent>
        <PageTitle textAlign='center'>Enter your comment</PageTitle>
        <PageSubtitle textAlign='center'>
          If you have any comments you want to add to the order, feel free to write in below. Review
          a summary of your reservation below
        </PageSubtitle>

        <TextAreaBox>
          <TextAreaTitle>Comment</TextAreaTitle>
          <TextArea
            value={text}
            minRows={6}
            placeholder='Please input comment or note'
            validationMessage={validationMessage}
            onChange={handleCommentChange}
          />
          {validationMessage && <TextMessage>{validationMessage}</TextMessage>}
        </TextAreaBox>
      </EnterCommentContent>

      <StepperButtons
        backButtonProps={{
          onClick: onBack
        }}
        nextButtonProps={{
          type: 'submit'
        }}
      />
    </EnterCommentContainer>
  )
}

export default EnterCommentStep

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Button } from '@components/common'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(({ theme }) => ({
  '.MuiButtonBase-root': {
    height: '36px'
  },
  [theme.breakpoints.down('md')]: {
    display: 'none !important'
  }
}))

export const StyledApprovedButton = styled(Button)(() => ({
  background: COLORS.white,
  color: COLORS.green,
  border: `1px solid ${COLORS.green}`,

  '&:hover': {
    background: COLORS.white
  }
}))

export const StyledCancelButton = styled(Button)(() => ({
  background: COLORS.white,
  color: COLORS.raisinBlack,
  border: `1px solid ${COLORS.raisinBlack}`,

  '&:hover': {
    background: COLORS.white,
    color: COLORS.raisinBlack,
    border: `1px solid ${COLORS.raisinBlack}`
  }
}))

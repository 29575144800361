import { messages, responseCases } from '@constants/errors'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormSetError } from 'react-hook-form'

export const errorSwitcher = (error: any, optionalKey: string): string[] | string => {
  if (error?.data && error?.data?.errors && error?.data?.errors?.error) {
    switch (error.data.errors.error) {
      case responseCases.NUMBER_EXIST:
        return ['number', messages.numberExist]
      case responseCases.EMAIL_EXIST:
      case responseCases.ALREADY_EXIST:
        return ['email', messages.emailExist]
      case responseCases.USER_NOT_FOUND:
      case responseCases.INVALID_CREDENTIALS:
        return ['email', messages.invalidCredential]
      default:
        return messages.something
    }
  } else if (error?.data && typeof error?.data?.errors === 'string') {
    return messages.something
  } else if (!error.length && optionalKey) {
    return [optionalKey, messages.invalidCredential]
  }

  return 'Something went wrong.'
}

interface IGenerateError<T extends FieldValues> {
  error: T
  setError: UseFormSetError<T>
  setElseError: Dispatch<SetStateAction<string>>
  optionalKey?: string
}

export const generateError = <T extends FieldValues>({
  error,
  setError,
  setElseError,
  optionalKey = ''
}: IGenerateError<T>) => {
  const result = errorSwitcher(error, optionalKey)

  if (typeof result === 'string') {
    setElseError(result)
  } else if (typeof result === 'object' && result.length > 0) {
    const [key, value] = result

    setError(key as any, { message: value })
  }
}

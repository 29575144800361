import { useState, memo, forwardRef, Ref } from 'react'
import { useFormContext } from 'react-hook-form'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { FormControl, InputLabel, FormHelperText } from '@mui/material'
import { StyledInput } from '../../styled'
import { NumberInput } from '../..'

interface IFormInputWrapper {
  key: string
  name: string
  type: string
  label: string
  placeholder: string
}

const FormInputWrapper = ({ label, type, ...rest }: IFormInputWrapper, ref: Ref<unknown>) => {
  const {
    formState: { errors }
  } = useFormContext()
  const [isEyeOpen, setEyeOpen] = useState(false)
  const isPassword = type === 'password'
  const textarea = type === 'textarea'
  const isNumber = type === 'number'

  const errorMessage =
    Object.keys(errors)?.length && errors[rest?.name] ? (errors[rest?.name]?.message as string) : ''

  return (
    <FormControl variant='standard'>
      <InputLabel shrink htmlFor={type} error={!!errors[rest.name]}>
        {label}
      </InputLabel>
      {isNumber ? (
        <NumberInput {...rest} />
      ) : (
        <StyledInput
          ref={ref}
          rows={3}
          type={isPassword ? (isEyeOpen ? 'text' : type) : type}
          multiline={textarea}
          error={!!errors[rest.name]}
          textarea={textarea ? 'is' : ''}
          endAdornment={
            isPassword ? (
              !isEyeOpen ? (
                <VisibilityOff onClick={() => setEyeOpen(!isEyeOpen)} />
              ) : (
                <VisibilityIcon onClick={() => setEyeOpen(!isEyeOpen)} />
              )
            ) : null
          }
          {...rest}
        />
      )}
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default memo(forwardRef(FormInputWrapper))

import { Button } from '@components/common'
import { Typography } from '@mui/material'
import { Section } from './styled'

const Oops = () => {
  return (
    <Section>
      <Typography component='h1' variant='h1'>
        Oops!
      </Typography>
      <Typography component='p' variant='body1'>
        Something went wrong
      </Typography>
      <Button type='button' color='primary'>
        <a href='/auth/sign-in'>Log In</a>
      </Button>
    </Section>
  )
}

export default Oops

import queryString from 'query-string'
import { privateRoutesPropsType } from '@collections/routes'

export const urlObjectParseToString = (urlObject: privateRoutesPropsType): string => {
  return queryString.stringify(urlObject)
}

export const urlStringParseToObject = (urlString: string) => {
  return queryString.parse(urlString)
}

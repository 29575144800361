import { COLORS } from '@collections/colors'
import InterFont from '@assets/fonts/Inter'
import SourceSerifFonts from '@assets/fonts/SourceSerifPro'

const MuiCssBaseline = {
  styleOverrides: {
    fontFaces: [SourceSerifFonts.fontFaces, InterFont.fontFaces],

    '*': {
      boxSizing: 'border-box !important',
      color: COLORS.raisinBlack,
      margin: '0px',
      padding: '0px'
    }
  }
}

export default MuiCssBaseline

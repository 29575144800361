import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledBox = styled(Box)`
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    word-break: break-word;
    -webkit-box-orient: vertical;
  }
`

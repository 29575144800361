import React from 'react'

import {
  Grid,
  Box,
  Popper,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  IconButton,
  css,
  MenuItem as MuiMenuItem
} from '@mui/material'
import COLORS from '@app/colors'
import Typography from '@mui/material/Typography'

export const PickCourseContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 30px;
    }
  `}
`

export const Selects = styled(Grid)`
  gap: 24px;
  justify-content: center;
  margin-top: 80px;
  display: grid;
  grid-template-columns: 300px 300px;

  .MuiTypography-body1 {
    text-align: left;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-bottom: 50px;
      grid-template-columns: 1fr;
    }
  `}
`

export const MenuItem = styled(MuiMenuItem)`
  white-space: pre-wrap;
  word-break: break-word;
`

export const ReviewBox = styled(Box)`
  background: white;
  border: 1px solid ${COLORS.black};
  border-radius: 10px;
  padding: 20px;
  max-width: 872px;
  align-self: center;
  margin: 24px auto 20px auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
      margin-bottom: 50px;
    }
  `}
`

export const ReviewBoxTitle = styled(Typography)`
  color: ${COLORS.black};
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 12px;
`

export const ReviewBoxCourseItem = styled(Box)`
  margin-bottom: 12px;
  display: flex;
  gap: 8px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 10px;
    }
  `}
`

export const ReviewBoxCourseTitle = styled(Box)`
  display: flex;
  gap: 5px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: start;
      gap: unset;
      margin-bottom: 10px;
    }
  `}
`

export const FeeInfo = styled(Typography)`
  font-family: 'Inter', serif;
  color: #808080;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  `}
`

export const OptionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'none',
    border: 'none'
  }
}))

export const OptionTooltipButton = styled(IconButton)`
  margin-left: 10px;
`

export const OptionTooltipContent = styled(Stack)`
  font-family: 'Inter', serif;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 12px;
  gap: 12px;
`

export const OptionTooltipTitle = styled(Typography)`
  font-weight: 600;
  font-size: 13px;
  color: ${COLORS.black};
`

export const OptionTooltipFee = styled(Typography)`
  color: #808080;
`

export const StyledPopper = styled(Popper)`
  background: transparent;

  .MuiPaper-root {
    margin-top: 12px;
  }
`

import { memo, Dispatch, SetStateAction } from 'react'

import type { IFilterOptions } from '@appTypes/model/filters.model'

import Autocomplete from '@mui/material/Autocomplete'

import Close from '@svgs/close.svg'

import { useSuggestion } from './useSuggestion'
import { StyledIcon, AutocompleteWrapper } from './styled'

interface ISuggestion {
  height?: string
  options: IFilterOptions[]
  placeholder: string
  inputValue: string
  selected: IFilterOptions | null
  setSelected: Dispatch<SetStateAction<IFilterOptions | null>>
  setInputValue: Dispatch<SetStateAction<string>>
}

const Suggestion = ({
  height,
  options,
  selected,
  inputValue,
  placeholder,
  setSelected,
  setInputValue
}: ISuggestion) => {
  const { handleChange, renderOption, renderInput } = useSuggestion({
    options,
    selected,
    setSelected
  })

  return (
    <AutocompleteWrapper className='hello'>
      <Autocomplete
        sx={{ height }}
        freeSolo
        disablePortal
        clearIcon={<StyledIcon src={Close} />}
        inputValue={inputValue}
        value={selected}
        options={options}
        onChange={handleChange}
        renderOption={renderOption}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return option?.name || ''
        }}
        renderInput={(params) => renderInput({ params, placeholder, setInputValue })}
      />
    </AutocompleteWrapper>
  )
}

export default memo(Suggestion)

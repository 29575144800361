import { IUserModel } from "@appTypes/model/user.model"

class BaseAPI {
  static user: boolean

  public static isUserExist() {
    if (!BaseAPI.user) {
      throw new Error('The user data missing')
    }
  }

  public static init(user: IUserModel | null) {
    BaseAPI.user = !!user
  }
}

export { BaseAPI }

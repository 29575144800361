import { memo } from 'react'

import { PAGE_ROUTES_PUBLIC, PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import LinkTo from '../common/LinkTo'
import { Wrapper, StyledTypography } from './styled'

interface IUnderlinedText {
  children: React.ReactNode
  link: PAGE_ROUTES_PUBLIC | PAGE_ROUTES_PRIVATE
}

const UnderlinedText = ({ link, children }: IUnderlinedText) => {
  return (
    <Wrapper>
      <StyledTypography variant='body2'>
        <LinkTo href={link}>{children}</LinkTo>
      </StyledTypography>
    </Wrapper>
  )
}

export default memo(UnderlinedText)

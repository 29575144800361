import { memo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { useAppDispatch } from '@store/hooks'
import CloseSvg from '@svgs/close.svg'
import EditTicket from '@svgs/editTicket.svg'
import { ITicket } from '@appTypes/global'
import { StyledIcon, StyledEditIcon } from '@components/TicketBox/styled'
import { fireFinishTicket } from '@store/features/ticket/ticket.actions'
import { setPopUpData } from '@store/features/general/general.slice'
import { saveTicketInStorage } from '@pages/CreateTicket/book-ticket'
import { getDateFromFirstGroup } from '@utils/time'
import { convertGroupsData } from '@utils/general'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'

const IsFinishedItem = ({ page, ticket }: { page: string; ticket: ITicket }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // const [searchParams] = useSearchParams()
  // if (page !== 'OpenTickets' || searchParams.get('isFinished') === 'true') {
  //   return null
  // }

  const isOpenTicketsPage = page === 'OpenTickets'

  return (
    <>
      <StyledEditIcon
        src={EditTicket}
        width={15}
        height={15}
        alt='close icon'
        openTickets={isOpenTicketsPage}
        onClick={() => {
          const dateFromFirstGroup = getDateFromFirstGroup(ticket.groups)
          const convertedGroups = convertGroupsData(ticket.groups)

          saveTicketInStorage({
            courses: ticket?.ticketCourses?.map(({ course }) => course) || [],
            groups: convertedGroups,
            comment: ticket.comment,
            date: {
              date: dateFromFirstGroup.toISOString(),
              interval: ticket.availability
            }
          })

          navigate({
            pathname: PAGE_ROUTES_PRIVATE.CREATE_TICKET,
            search: createSearchParams({ ticketId: ticket.id }).toString()
          })
        }}
      />
      {
        isOpenTicketsPage && (
          <StyledIcon
            src={CloseSvg}
            alt='close icon'
            onClick={() => {
              dispatch(fireFinishTicket({ id: ticket.id }))
                .then(() => {
                  dispatch(setPopUpData({ status: true, popUpId: ticket.id }))
                })
                .catch(console.log)
            }}
          />
        )
      }
    </>
  )
}

export default memo(IsFinishedItem)

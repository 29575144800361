import { createSlice } from '@reduxjs/toolkit'

import { IGeneralInitial } from '@store/features/general/general.types'

const initialState: IGeneralInitial = {
  disableBackButton: false,
  popUp: {
    popUpType: null,
    popUpId: null,
    status: false,
    additional: null
  },
  prevPages: []
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setBackDisable: (state, action) => {
      state.disableBackButton = action.payload.disableBackButton
    },
    setPopUpData: (state, action) => {
      return {
        ...state,
        popUp: {
          ...state.popUp,
          ...action.payload
        }
      }
    },
    savePrevPathname: (state, action) => {
      const newPaths = [...state.prevPages]

      if (action.payload.pathname) newPaths.push(action.payload.pathname)

      state.prevPages = newPaths
    },
    deleteLastPathname: (state) => {
      const newPaths = [...state.prevPages]

      newPaths.pop()
      state.prevPages = newPaths
    }
  }
})

export const { setBackDisable, setPopUpData, savePrevPathname, deleteLastPathname } =
  generalSlice.actions

export default generalSlice.reducer

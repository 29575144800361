import useScreenSize from '@hook/useScreenSize'
import { IFiltersOrders, collection } from './data'

interface IFilterType {
  id: number
  type: string
  key: string
  value: string
  title: string
}

interface IFiltersOrderController {
  filtersOrders: IFiltersOrders
}

export const filtersOrderController = ({ filtersOrders }: IFiltersOrderController) => {
  const isMobile = useScreenSize()
  const filterItems = [] as IFilterType[]
  const array = isMobile ? filtersOrders?.mobile || [] : filtersOrders?.desktop || []

  array.forEach((key) => {
    const item = collection[key as keyof typeof collection]

    if (!item) {
      return
    }

    filterItems.push(item)
  })

  return filterItems
}

import { ChangeEvent, Dispatch, memo, SetStateAction, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import SearchSvg from '@assets/svgs/search.svg'
import CloseSvg from '@assets/svgs/close.svg'

import Typography from '@mui/material/Typography'

import type { IFiltersProps, IFilterOptions } from '@appTypes/model/filters.model'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import DropDownFilterByDate from '@components/DropDownFilterByDate'

import { ReactComponent as ArrowBottomGray } from '@assets/svgs/arrowBottomGray.svg'
import { ReactComponent as ArrowBottomBlack } from '@assets/svgs/arrowBottomBlack.svg'
import { Search } from '@components/common/Inputs'
import { filtersOrderController } from '@components/Filters/helper'
import { FiltersContext } from '@contexts/filters'
import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { Button, LinkTo } from '@components/common'
import { FiltersViewEnum, IFiltersOrders } from '@components/Filters/data'
import Suggestion from '../Suggestion'
import { FilterItem, FilterItemAlways, StyledButtonForOpenTickets } from '../../styled'
import { StyledApprovedButton, StyledCancelButton } from '../FilterButtonsWrapper/styled'

interface ISimpleFiltersSwitcher {
  total: number
  page: string
  members: IFilterOptions[]
  inputValue: string
  placeholder: string
  selected: IFilterOptions | null
  searchByKey: string
  filtersOrders: IFiltersOrders
  handleSelectChange: (data: IFiltersProps) => void
  setInputValue: Dispatch<SetStateAction<string>>
  setSearchByKey: Dispatch<SetStateAction<string>>
  setSelected: Dispatch<SetStateAction<IFilterOptions | null>>
}

// hereeeeeeeeee move to global place

const approvedButton = (
  total: number,
  collectQueryParams: (data: IFiltersProps, array?: string[] | undefined) => void
) => (
  <StyledApprovedButton
    type='button'
    color='primary'
    variant='contained'
    onClick={() => collectQueryParams({ status: FiltersStatusesEnum.APPROVED })}
  >
    Approved {total ? `(${total})` : ''}
  </StyledApprovedButton>
)

const declinedButton = (
  total: number,
  collectQueryParams: (data: IFiltersProps, array?: string[] | undefined) => void
) => (
  <Button
    type='button'
    color='secondary'
    variant='outlined'
    onClick={() => collectQueryParams({ status: FiltersStatusesEnum.DECLINED })}
  >
    Declined {total ? `(${total})` : ''}
  </Button>
)

const cancelButton = (
  total: number,
  collectQueryParams: (data: IFiltersProps, array?: string[] | undefined) => void
) => (
  <StyledCancelButton
    type='button'
    color='secondary'
    variant='outlined'
    onClick={() => collectQueryParams({ status: FiltersStatusesEnum.APPROVED_CANCELED })}
  >
    Cancelled {total ? `(${total})` : ''}
  </StyledCancelButton>
)

const SimpleFiltersSwitcher = ({
  total,
  filtersOrders,
  page,
  members,
  inputValue,
  placeholder,
  selected,
  searchByKey,
  setInputValue,
  setSelected,
  setSearchByKey,
  handleSelectChange
}: ISimpleFiltersSwitcher) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { collectQueryParams } = useContext(FiltersContext)

  const filterItems = filtersOrderController({ filtersOrders })

  return (
    <>
      {filterItems.map((item) => {
        if (item?.type === 'simple' && item?.key) {
          const active = searchParams.get(item.key) === item.value

          if (item.id === 3) {
            return (
              <DropDownFilterByDate key={item.value}>
                <FilterItemAlways
                  key={item.value}
                  active={
                    searchParams.get('dateFrom') || searchParams.get('dateTo') ? 'active' : ''
                  }
                >
                  <Typography component='p'>{item.title}</Typography>
                  {searchParams.get('dateFrom') || searchParams.get('dateTo') ? (
                    <ArrowBottomBlack />
                  ) : (
                    <ArrowBottomGray />
                  )}
                </FilterItemAlways>
              </DropDownFilterByDate>
            )
          }

          return (
            <FilterItem
              key={item.id}
              active={active ? 'active' : ''}
              onClick={() => handleSelectChange({ [item.key]: item.value })}
            >
              <Typography component='p'>{item.title}</Typography>
            </FilterItem>
          )
        }

        if (item.type === FiltersViewEnum.allMembersAndAllCourses) {
          return (
            <Search
              key={item.id}
              placeholder={placeholder}
              value={searchByKey}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchByKey(e.target.value || '')
              }}
              endAdornment={
                !searchByKey ? (
                  <img src={SearchSvg} alt='Search' loading='lazy' />
                ) : (
                  <img
                    src={CloseSvg}
                    alt='Close'
                    loading='lazy'
                    onClick={() => {
                      setSearchByKey('')
                    }}
                  />
                )
              }
            />
          )
        }

        if (item.type === FiltersViewEnum.memberSearch) {
          return (
            <Suggestion
              key={item.id}
              placeholder={placeholder}
              options={members}
              selected={selected}
              inputValue={inputValue}
              setSelected={setSelected}
              setInputValue={setInputValue}
            />
          )
        }

        if (item.type === FiltersViewEnum.createTicketButton) {
          return (
            <div key={item.id}>
              <StyledButtonForOpenTickets
                variant='contained'
                color='primary'
                type='button'
                onClick={() => {
                  navigate(PAGE_ROUTES_PRIVATE.CREATE_TICKET)
                }}
              >
                Create a Ticket
              </StyledButtonForOpenTickets>
            </div>
          )
        }

        if (
          item.type === FiltersViewEnum.approvedButton ||
          item.type === FiltersViewEnum.declinedButton ||
          item.type === FiltersViewEnum.canceledButton
        ) {
          if (!searchParams.get('status')) {
            if (item.type === FiltersViewEnum.approvedButton) {
              return <span key={item.id}>{approvedButton(0, collectQueryParams)}</span>
            }

            if (item.type === FiltersViewEnum.declinedButton) {
              return <span key={item.id}>{declinedButton(0, collectQueryParams)}</span>
            }

            if (item.type === FiltersViewEnum.canceledButton) {
              return <span key={item.id}>{cancelButton(0, collectQueryParams)}</span>
            }
          } else {
            if (
              searchParams.get('status') === FiltersStatusesEnum.APPROVED &&
              item.type === FiltersViewEnum.approvedButton
            ) {
              return <span key={item.id}>{approvedButton(total, collectQueryParams)}</span>
            }

            if (
              searchParams.get('status') === FiltersStatusesEnum.DECLINED &&
              item.type === FiltersViewEnum.declinedButton
            ) {
              return <span key={item.id}>{declinedButton(total, collectQueryParams)}</span>
            }

            if (
              searchParams.get('status') === FiltersStatusesEnum.APPROVED_CANCELED &&
              item.type === FiltersViewEnum.canceledButton
            ) {
              return <span key={item.id}>{cancelButton(total, collectQueryParams)}</span>
            }
          }
        }

        if (item.type === FiltersViewEnum.createACourse) {
          return (
            <LinkTo key={item.id} href={PAGE_ROUTES_PRIVATE.CREATE_COURSE}>
              <Button variant='contained' color='primary' type='button'>
                Create a Course
              </Button>
            </LinkTo>
          )
        }

        if (item.type === FiltersViewEnum.addAMember) {
          return (
            <LinkTo key={item.id} href={PAGE_ROUTES_PRIVATE.ADD_MEMBER}>
              <Button variant='contained' color='primary' type='button'>
                Add a Member
              </Button>
            </LinkTo>
          )
        }
      })}
    </>
  )
}

export default memo(SimpleFiltersSwitcher)

import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: any): T => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current as T
}

export default usePrevious

import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { validate as uuidValidate } from 'uuid'

import { deleteLastPathname } from '@store/features/general/general.slice'
import { useAppSelector } from '@store/hooks'
import { Button } from '@components/common'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { StyledBox } from './styled'

const ContentHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const owner = useAppSelector((state) => state.auth?.user?.club?.owner)
  const { prevPages } = useAppSelector((state) => state.general)

  const handleGoBack = () => {
    const path: string = prevPages.at(-1) as string
    const paths = path?.split('/') || ''

    if (uuidValidate(paths[paths.length - 1])) {
      const pathMinus2: string = prevPages.at(-2) as string
      dispatch(deleteLastPathname())
      dispatch(deleteLastPathname())
      navigate(pathMinus2)
      return
    }

    if (path === '/' || path === '/ticket-history' || path === '/open-tickets') {
      dispatch(deleteLastPathname())
      navigate(path)
      return
    }

    dispatch(deleteLastPathname())
    navigate(-1)
  }

  if (location.pathname === '/') {
    return (
      <Typography component='h5' variant='h5'>
        Welcome, {owner}!
      </Typography>
    )
  }

  const renderButtonOnRightSide = () => {
    // hereeeee by enum
    if (location.pathname.includes('/open-tickets')) {
      return (
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={() => {
            navigate(PAGE_ROUTES_PRIVATE.CREATE_TICKET)
          }}
        >
          Create a Ticket
        </Button>
      )
    }

    if (location.pathname.includes('/courses')) {
      return (
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={() => {
            navigate(PAGE_ROUTES_PRIVATE.CREATE_COURSE)
          }}
        >
          Create a Course
        </Button>
      )
    }
    if (location.pathname.includes('/members')) {
      return (
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={() => {
            navigate(PAGE_ROUTES_PRIVATE.ADD_MEMBER)
          }}
        >
          Add a Member
        </Button>
      )
    }
  }

  return (
    <StyledBox>
      <div onClick={handleGoBack}>
        <NavigateBeforeIcon />
        Back
      </div>
      {renderButtonOnRightSide()}
    </StyledBox>
  )
}

export default ContentHeader

export const messages = {
  something: 'Something went wrong.',
  emailExist: 'The email address already exists.',
  numberExist: 'The number already exists.',
  user: 'The user not found.',
  invalidCredential: 'Invalid credentials'
}

export const responseCases = {
  NUMBER_EXIST: 'NUMBER_EXIST',
  EMAIL_EXIST: 'EMAIL_EXIST',
  ALREADY_EXIST: 'ALREADY_EXIST',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS'
}

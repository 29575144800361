import React from 'react'

import BoxLoader from '@components/TicketLoaderWrapper/components/BoxLoader'
import { Wrapper } from './styled'

interface ITicketLoaderWrapper {
  loader: boolean
  children: React.ReactNode
}

const TicketLoaderWrapper = ({ children, loader }: ITicketLoaderWrapper) => {
  if (loader) {
    return (
      <Wrapper>
        {['1', '2', '3', '4', '5', '6'].map((item) => (
          <BoxLoader key={item} />
        ))}
      </Wrapper>
    )
  }

  return <>{children}</>
}

export default TicketLoaderWrapper

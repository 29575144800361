import { IGroup } from "@appTypes/global"

export const handleFixingOffsetAfterEveryDeletedItem = ({
  deleted,
  searchParams
}: {
  deleted: number
  searchParams: URLSearchParams
}) => {
  if (deleted !== 0) {
    const offset = searchParams.get('offset')
    if (typeof offset === 'string') {
      const offsetNum = +offset
      let sum = offsetNum - deleted

      if (sum < 0) {
        sum = 0
      }

      searchParams.set('offset', `${sum}`)
    }
  }

  return searchParams.toString()
}

export const convertGroupsData = (groups: IGroup[]) => {
  return groups.map((group) => {
    let collectGroup = [] as any[]

    if ('members' in group) {
      const newMembers = (group.members || []).map((g) => ({
        isMember: true,
        id: g.id,
        fullName: g.fullName
      }))

      collectGroup = [...collectGroup, ...newMembers]
    }

    if ('guests' in group) {
      const newGuests = (group.guests || []).map((g) => ({
        isMember: false,
        id: g.id,
        fullName: g.fullName
      }))

      collectGroup = [...collectGroup, ...newGuests]
    }

    return {
      ...group,
      members: collectGroup
    }
  })
}

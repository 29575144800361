import { memo } from 'react'

import MuiFormHelperText from '@mui/material/FormHelperText'
import { StyledWrapper } from './styled'

interface IFormHelperText {
  elseError: string
}

export const FormHelperText = ({ elseError }: IFormHelperText) => {
  return (
    <StyledWrapper>{elseError && <MuiFormHelperText>{elseError}</MuiFormHelperText>}</StyledWrapper>
  )
}

export default memo(FormHelperText)

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { RouteEnum } from '@appTypes/enums/global'

export const StyledWrapper = styled(Box)<{ type: RouteEnum }>(({ theme, type }) => ({
  // minus header and margin values from height property
  background:
    type === RouteEnum.PUBLIC ? theme.palette.background.paper : theme.palette.background.default,

  overflow: 'hidden',
  minHeight: 'calc(100vh - 75px)',

  '> .MuiBox-root': {
    position: 'relative',
    width: '100%',
    padding: '49px 40px',
    margin: '40px auto 40px auto',
    maxWidth: theme.breakpoints.values.xl,
    background: theme.palette.background.paper,
    minHeight: 'calc(100vh - 75px - 80px)'
    // minHeight: 'max-content'
  },

  [theme.breakpoints.down('xl')]: {
    padding: '25px 25px 45px 25px',
    background: 'white',

    '> .MuiBox-root': {
      padding: '25px',
      margin: '0',
      background: 'white'
    }
  },

  [theme.breakpoints.down('md')]: {
    padding: '0px',
    background: 'white',
    minHeight: 'max-content',

    '> .MuiBox-root': {
      margin: '0',
      background: 'white',
      padding: '10px 20px 20px 20px',
      // minHeight: 'calc(100vh - 65px)',
      minHeight: 'max-content'
    }
  }
}))

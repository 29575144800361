/* eslint-disable indent */
import { memo } from 'react'

import { ITicket } from '@appTypes/global'
import { Container, Group, GroupWrapper, MembersWrapper, StyledTypography } from './styled'

const MembersAndGroups = ({ ticket }: { ticket: ITicket }) => {
  const groupsList = ticket?.groups || []

  const groups = groupsList.map((group, index) => {
    const { members, guests } = group

    const membersAndGuests = [] as {
      id: string
      fullName: string
      isMember: boolean
      number: string
    }[]

    members.forEach((member) => {
      membersAndGuests.push({
        id: member.id,
        number: member.number,
        fullName: member.fullName,
        isMember: true
      })
    })

    guests.forEach((member) => {
      membersAndGuests.push({
        id: member.id,
        fullName: member.fullName,
        number: '',
        isMember: false
      })
    })

    return (
      <Group key={group.id}>
        <GroupWrapper>
          <span>Group {index + 1}</span>
        </GroupWrapper>
        <MembersWrapper>
          {membersAndGuests.map((item) => {
            return (
              <StyledTypography
                key={item.id}
                className={item.isMember ? 'member' : 'guest'}
              >
                {item.fullName}
                {
                  item.number && (
                    <>
                       <svg width='3' height='4' viewBox='0 0 3 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <circle cx='1.5' cy='2' r='1.5' />
                       </svg>
                      <span>
                        ID: {item.number}
                      </span>
                    </>
                  )
                }
              </StyledTypography>
            )
          })}
        </MembersWrapper>
      </Group>
    )
  })

  return <Container>{groups}</Container>
}

export default memo(MembersAndGroups)

/* eslint-disable no-multiple-empty-lines */
import { createFilterOptions } from '@mui/material/Autocomplete'

import * as yup from 'yup'
import { FilterOptionsState } from '@mui/material'

import {
  MemberOptionType,
  ISelectMembersFormValues,
  IGroupsOptionType,
  ISelectGroupsFormValues
} from '../types'
import { CourseStorageKeys } from '../constants'

export const initialMembersData: ISelectMembersFormValues = {
  members: []
}

export const getSelectMembersInitialValues = () => {
  const data = localStorage.getItem(CourseStorageKeys.MEMBERS)

  const dataM: ISelectMembersFormValues = data ? JSON.parse(data) : null

  return dataM?.members?.length > 0 ? dataM : { members: [] }
}

interface groupUpdateInterface {
  groupId: string
  members: MemberOptionType[]
  onUpdate: (groups: ISelectGroupsFormValues[]) => void
}

type groupFromStorageType = ISelectGroupsFormValues[] | null

export const getSelectedGroupsFromLocalStorage = (
  groups: ISelectGroupsFormValues[]
): ISelectGroupsFormValues[] => {
  const data = localStorage.getItem(CourseStorageKeys.GROUPS)

  const dataM: ISelectGroupsFormValues[] = data ? JSON.parse(data) : null

  const result = dataM?.length > 0 ? dataM : groups

  // update by initial state if that case, when local storage is missing
  localStorage.setItem(CourseStorageKeys.GROUPS, JSON.stringify(result))

  return result
}

export const addNewGroupInLocalStorage = (group: ISelectGroupsFormValues) => {
  const data = localStorage.getItem(CourseStorageKeys.GROUPS)

  if (data) {
    const groups = JSON.parse(data)

    localStorage.setItem(CourseStorageKeys.GROUPS, JSON.stringify([...groups, group]))
  }
}

export const deleteGroupFromLocalStorage = (groupId: string): void => {
  const dataString = localStorage.getItem(CourseStorageKeys.GROUPS)

  if (dataString) {
    let groups = JSON.parse(dataString) as ISelectGroupsFormValues[]

    groups = groups.filter((group) => group.id !== groupId)

    localStorage.setItem(CourseStorageKeys.GROUPS, JSON.stringify(groups))
  }
}

export const updateMembersInLocalStorageGroups = ({
  groupId,
  members,
  onUpdate
}: groupUpdateInterface) => {
  const groupsJson = localStorage.getItem(CourseStorageKeys.GROUPS)

  const sortedMembers = [] as MemberOptionType[]

  members.forEach((item) => sortedMembers.push(item))

  if (!groupsJson) {
    return
  }

  let groups: groupFromStorageType = JSON.parse(groupsJson) as groupFromStorageType

  if (groups) {
    const isFound = groups.find((group) => group.id === groupId)

    if (isFound) {
      groups = groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            members: sortedMembers
          }
        }

        return group
      })

      onUpdate(groups)
      localStorage.setItem(CourseStorageKeys.GROUPS, JSON.stringify(groups))
    }
  }
}

export const saveSelectMembersDataInStorage = (values: ISelectMembersFormValues) => {
  localStorage.setItem(CourseStorageKeys.MEMBERS, JSON.stringify(values))
}

const filter = createFilterOptions<MemberOptionType>()

export const filterOptions = (
  options: MemberOptionType[],
  state: FilterOptionsState<MemberOptionType>
) => {
  const filtered = filter(options, state)

  const { inputValue } = state
  const isExisting = options.some((option) => inputValue === option.id)

  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      id: Math.random().toString(),
      isMember: false,
      fullName: `Add guest "${inputValue}"`
    })
  }

  return filtered
}

export const getOptionLabel = (option: MemberOptionType | string) => {
  if (typeof option === 'string') {
    return option
  }

  return option.inputValue ? option.inputValue : option.fullName
}

export const isOptionEqualToValue = (a: MemberOptionType, b: MemberOptionType) => a.id === b.id

export const SelectMembersFormValidation = yup.object({
  members: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string().required()
        })
        .required()
    )
    .required()
    .min(1, 'You must add at least 1 Member to proceed with the reservation.')
})

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      height: '18px',
      margin: '18px 0 10px 0',

      '.MuiTypography-root': {
        marginLeft: '8px'
      }
    }
  }
}

export default MuiFormControlLabel

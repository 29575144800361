import { PagesInHomeStaticData } from '@appTypes/global'
import { headerPages } from '@collections/routes'
import { useAppSelector } from '@store/hooks'
import { useEffect, useState } from 'react'

const useHome = () => {
  const [pages, setPages] = useState([] as PagesInHomeStaticData[])
  const ownerName = useAppSelector((state) => state.auth.user?.club?.owner)

  useEffect(() => {
    const content = [] as PagesInHomeStaticData[]

    for (let i = 0; i < headerPages.length; i++) {
      const item = headerPages[i]

      if (item?.options?.length) {
        for (let i = 0; i < item.options.length; i++) {
          if (item.options[i].link) {
            const { options, ...rest } = item.options[i]
            content.push(rest)
          }
        }
      }

      if (item.link) {
        const { options, ...rest } = headerPages[i]
        content.push(rest)
      }
    }

    setPages(content)
  }, [])

  return { pages, ownerName }
}

export default useHome

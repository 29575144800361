import Typography from '@mui/material/Typography'
import { useAppDispatch } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'

import CloseSvg from '@svgs/close.svg'
import { Section, StyledIcon } from './styled'

const DialogAlertCancellationDoneVia = () => {
  const dispatch = useAppDispatch()

  const handleCancel = () => {
    dispatch(setPopUpData({ popUpType: null, popUpId: null, status: false }))
  }

  return (
    <Section>
      <StyledIcon src={CloseSvg} alt='close icon' onClick={handleCancel} />
      <Typography component='h4' variant='h4' align='center'>
        Cancel Confirmation Done
      </Typography>
    </Section>
  )
}

export default DialogAlertCancellationDoneVia

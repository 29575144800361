import { COLORS } from '@collections/colors'

const MuiTypography = {
  styleOverrides: {
    root: {
      lineHeight: 'normal !important'
    },
    body1: {
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '18.2px',
      color: COLORS.raisinBlack
    },
    body2: {
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '140%',
      letterSpacing: '-0.01em'
    },
    subtitle1: {
      // fontSize: '15px',
      // lineHeight: '18px',
      // cursor: 'pointer',
      // color: 'rgba(32, 31, 33, 0.5)',
      // a: {
      //   textDecorationLine: 'underline'
      // }
    },
    subtitle2: {
      // fontSize: '13px',
      // fontWeight: '500',
      // lineHeight: '140%',
      // color: 'rgba(0, 0, 0, 0.5)'
    }
  }
}

export default MuiTypography

import { useEffect, useState } from 'react'

import type { ISelectOption, ITicket } from '@appTypes/global'

import { dayJsWrap, getDateFromFirstGroup } from '@utils/time'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'
import { getTicketThunk, makeTicketCounterThunk } from '@store/features/ticket/ticket.actions'
import { getCoursesThunk } from '@store/features/course/course.actions'
import { ICourse, ICourseResponse } from '@store/features/course/course.types'

interface LocalState {
  message: string
  isLoading: boolean
  ticket: ITicket
  courses: ISelectOption[]
  originalCourses: ICourse[]
  executionStatus: string | null
  selectedCourse: ISelectOption | null
}

const useTicketCounterHook = () => {
  const dispatch = useAppDispatch()
  const { popUpId } = useAppSelector((state) => state.general.popUp)
  const [state, setState] = useState<LocalState>({
    ticket: {} as ITicket,
    executionStatus: null,
    message: '',
    courses: [],
    originalCourses: [],
    isLoading: true,
    selectedCourse: null
  })

  const dayJsDate = dayJsWrap(state.ticket.date)

  const handlingSituation = ({ message }: { message: string }) => {
    setState((prev) => {
      return {
        ...prev,
        courses: [],
        isLoading: false,
        executionStatus: message
      }
    })
  }

  const handleUpdate = () => {
    const { ticket, selectedCourse, originalCourses } = state

    const payload = { courseId: selectedCourse?.value || '', date: ticket.date }
    const found = originalCourses.find((course) => course.id === payload.courseId)

    dispatch(
      makeTicketCounterThunk({
        payload,
        ticketId: ticket.id
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setPopUpData({ status: true, additional: { date: ticket?.date, selectedCourse: found } })
        )
        handlingSituation({ message: 'Counter sent successfully' })
      })
      .catch(() => {
        handlingSituation({ message: 'There is no full information about this ticket' })
      })
  }

  const handleCancel = () => {
    dispatch(setPopUpData({ popUpType: null, popUpId: null, status: false }))
  }

  useEffect(() => {
    if (!popUpId) return
    setState((prev) => ({ ...prev, isLoading: true }))

    const promises = [
      dispatch(getTicketThunk({ id: popUpId })).unwrap(),
      dispatch(getCoursesThunk({ url: '' })).unwrap()
    ]

    Promise.all(promises)
      .then((response) => {
        const ticketResponse = response?.[0] as ITicket
        const coursesResponse = response?.[1] as ICourseResponse<ICourse[]>

        if (!ticketResponse || !coursesResponse) {
          setState((prev) => ({ ...prev, isLoading: false, courses: [] }))
          return
        }

        const courses = coursesResponse?.data.map((item) => ({
          name: item.name,
          value: item.id
        }))

        const date = getDateFromFirstGroup(ticketResponse.groups)

        setState((prev) => {
          return {
            ...prev,
            courses,
            isLoading: false,
            ticket: {
              ...ticketResponse,
              date: date.toISOString()
            },
            originalCourses: coursesResponse?.data,
            selectedCourse: courses[0]
          }
        })
      })
      .catch(() => {
        handlingSituation({ message: 'There is no full information about this ticket' })
      })
  }, [])

  return { ...state, dayJsDate, setState, handleCancel, handleUpdate }
}

export default useTicketCounterHook

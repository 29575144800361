import { useEffect, useState } from 'react'

import type { ISelectOption, ITicket } from '@appTypes/global'

import { dayJsWrap } from '@utils/time'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'
import { getTicketThunk } from '@store/features/ticket/ticket.actions'
import { ICourse } from '@store/features/course/course.types'

interface LocalState {
  message: string
  isLoading: boolean
  ticket: ITicket
  courses: ISelectOption[]
  originalCourses: ICourse[]
}

const useSeeMoreHook = () => {
  const dispatch = useAppDispatch()
  const { popUpId } = useAppSelector((state) => state.general.popUp)
  const [state, setState] = useState<LocalState>({
    ticket: {} as ITicket,
    message: '',
    courses: [],
    originalCourses: [],
    isLoading: true
  })

  const dayJsDate = dayJsWrap(state.ticket.date)

  const handlingSituation = ({ message }: { message: string }) => {
    setState((prev) => {
      return {
        ...prev,
        courses: [],
        isLoading: false,
        executionStatus: message
      }
    })
  }

  const handleCancel = () => {
    dispatch(setPopUpData({ popUpType: null, popUpId: null, status: false }))
  }

  useEffect(() => {
    if (!popUpId) return
    setState((prev) => ({ ...prev, isLoading: true }))

    const promises = [dispatch(getTicketThunk({ id: popUpId })).unwrap()]

    Promise.all(promises)
      .then((response) => {
        const ticketResponse = response?.[0] as ITicket

        if (!ticketResponse) {
          setState((prev) => ({ ...prev, isLoading: false, courses: [] }))
          return
        }

        setState((prev) => {
          return {
            ...prev,
            isLoading: false,
            ticket: ticketResponse
          }
        })
      })
      .catch(() => {
        handlingSituation({ message: 'There is no full information about this ticket' })
      })
  }, [])

  return { ...state, dayJsDate, setState, handleCancel }
}

export default useSeeMoreHook

import { createSlice } from '@reduxjs/toolkit'

import { IAuthState } from '@store/features/auth/auth.types'
import {
  loginThunk,
  profileThunk,
  logoutThunk,
  setPasswordThunk,
  resetPasswordThunk
} from '@store/features/auth/auth.actions'
import StorageManager from '@utils/storage-manager'
import { StorageKeysEnum } from '@appTypes/enums/global'

const initialState: IAuthState = {
  user: null,
  errors: null,
  accessToken: StorageManager.getString(StorageKeysEnum.ACCESS_TOKEN) || '',
  refreshToken: StorageManager.getString(StorageKeysEnum.REFRESH_TOKEN) || '',
  execution: {
    isLoading: false,
    status: false
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    executionLoading: (state, { payload }) => {
      state.execution.isLoading = payload.isLoading
    },
    resetExecutionState: (state) => {
      state.execution.status = false
      state.execution.isLoading = false
    },
    resetAuthState: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      // Login cases ...
      .addCase(loginThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(loginThunk.fulfilled, (state, { payload }) => {
        state.execution.isLoading = false
        state.accessToken = payload.accessToken
        state.refreshToken = payload.refreshToken
        state.user = {
          ...payload.userData.user,
          club: payload.userData.club
        }
      })
      .addCase(loginThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
      // Logout cases ...
      .addCase(logoutThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.accessToken = ''
        state.refreshToken = ''
        state.user = null
      })
      .addCase(logoutThunk.rejected, (state) => {
        state.execution.isLoading = false
        state.accessToken = ''
        state.refreshToken = ''
        state.user = null
      })
      // Profile cases ...
      .addCase(profileThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(profileThunk.fulfilled, (state, { payload }) => {
        state.execution.isLoading = false
        state.user = {
          ...payload.userData.user,
          club: payload.userData.club
        }
      })
      .addCase(profileThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
      // Forgot Password cases ...
      .addCase(resetPasswordThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = true
      })
      .addCase(resetPasswordThunk.rejected, (state) => {
        state.execution.isLoading = false
        state.execution.status = false
      })
      // Set Password cases ...
      .addCase(setPasswordThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(setPasswordThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = true
      })
      .addCase(setPasswordThunk.rejected, (state) => {
        state.execution.isLoading = false
        state.execution.status = false
      })
  }
})

export const { resetAuthState, executionLoading, resetExecutionState } = authSlice.actions

export default authSlice.reducer

import { Dispatch, SetStateAction } from 'react'
import HomeViewMobileVersion from './components/HomeViewMobileVersion'
import { MobileHeaderContainer } from './styled'

const MobileHeader = ({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <MobileHeaderContainer isopen={isOpen ? 'open' : ''}>
      <HomeViewMobileVersion setIsOpen={setIsOpen} />
    </MobileHeaderContainer>
  )
}

export default MobileHeader

import { memo } from 'react'
import { FormProvider } from 'react-hook-form'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'

import { Button } from '@components/common'
import { FormInputWrapper } from '@components/common/Inputs'
import { useChangePassword } from './useChangePassword'
import { Sent } from './components'
import { Form } from './styled'

const ChangePassword = () => {
  const {
    isLoading,
    status,
    elseError,
    methods,
    fields,
    handleForm,
    handleSubmit
  } = useChangePassword()

  if (status) {
    return <Sent />
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleForm)}>
        <Typography variant='h1'>Change Password</Typography>
        <Typography component='p' variant='body1'>
          Set the new password for your account so you can login and access all the features.
        </Typography>
        <Box>
          {fields.map((field) => (
            <FormInputWrapper {...field} />
          ))}
          {elseError && <FormHelperText>{elseError}</FormHelperText>}
          <Button type='submit' disabled={isLoading} isLoading={isLoading}>
            Continue
          </Button>
        </Box>
      </Form>
    </FormProvider>
  )
}

export default memo(ChangePassword)

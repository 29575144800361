import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { COLORS } from '@collections/colors'
import { Typography } from '@mui/material'

export const Section = styled('section')(({ theme }) => ({
  display: 'grid',
  gridGap: '40px',
  gridTemplateColumns: 'repeat(3, 1fr)',
  marginTop: '40px',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
  },
  [theme.breakpoints.down('md')]: {
    gap: '15px',
    gridTemplateColumns: '1fr 1fr'
  }
}))

export const TopSection = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  button: {
    width: '100px',
    fontSize: '13px'
  },

  [theme.breakpoints.down('md')]: {}
}))

export const BoxItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  maxWidth: '400px',
  width: '100%',
  height: '100px',
  borderRadius: '10px',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '22px',
  border: `1px solid ${COLORS.raisinBlack}`,
  color: COLORS.raisinBlack,
  background: COLORS.white,

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    fontSize: '14px',
    height: '60px'
  }
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'none',
  fontWeight: '800',

  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {}
}))

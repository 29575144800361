import { styled, TextField, Typography } from '@mui/material'
import COLORS from '@app/colors'

export const StyledTextField = styled(TextField)`
  border-radius: 10px;
  margin-top: 10px;

  .MuiInputBase-root {
    border-radius: 10px;
    background: white;
  }

  .MuiInputBase-input {
    padding: 16px;
  }

  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px #fff inset;
    }
  }
`

export const StyledTextFieldLabel = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${COLORS.black};
`

import { Box, styled, Typography } from '@mui/material'
import COLORS from '@app/colors'

export const InfoBlockTextDots = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter, serif',
  fontWeight: '400',
  fontSize: '13px',
  color: COLORS.black,
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  width: '180px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginTop: '10px',

  [theme.breakpoints.down('md')]: {
    marginTop: '0px'
  }
}))

export const MembersWrapper = styled(Box)`
  > span {
    color: rgba(37, 37, 37, 0.5);
    font-family: 'Inter', serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`

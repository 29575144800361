import { TimeZone } from '@constants/time'

import { dayJsTz } from '@utils/dayjs'
import { IGroup } from '@appTypes/global'
import { ICourse } from '../types'
import { CourseStorageKeys, Steps } from '../constants'
import { savePickCourseDataInStorage } from '../helpers/pickCourseHelpers'
import { saveSelectDateDataInStorage } from '../helpers/selectDateHelpers'
import { saveCommentDataInStorage } from '../helpers/enterCommentHelpers'

export const getInitialActiveStep = () => {
  const activeStep = localStorage.getItem(CourseStorageKeys.ACTIVE_STEP)

  return Number(activeStep) || Steps.COURSE
}

export const saveActiveStepDataInStorage = (step: Steps) => {
  localStorage.setItem(CourseStorageKeys.ACTIVE_STEP, step.toString())
}

export const saveSelectGroupsDataInStorage = (values: IGroup[]) => {
  localStorage.setItem(CourseStorageKeys.GROUPS, JSON.stringify(values))
}

export const clearTicketStorage = () => {
  localStorage.removeItem(CourseStorageKeys.ACTIVE_STEP)
  localStorage.removeItem(CourseStorageKeys.COURSE)
  localStorage.removeItem(CourseStorageKeys.DATE)
  localStorage.removeItem(CourseStorageKeys.GROUPS)
  localStorage.removeItem(CourseStorageKeys.MEMBERS)
  localStorage.removeItem(CourseStorageKeys.COMMENT)
}

export const saveTicketInStorage = (data: {
  courses: ICourse[]
  date: { date: string; interval: number }
  groups: IGroup[]
  comment: string
}) => {
  const { courses, date, groups, comment } = data

  const ticketDate = dayJsTz(TimeZone, date.date)

  savePickCourseDataInStorage({ courses })
  saveSelectDateDataInStorage({
    date: ticketDate,
    time: ticketDate.format('hh:mm A'),
    interval: date.interval
  })

  saveSelectGroupsDataInStorage(groups)
  saveCommentDataInStorage({ text: comment })
}

import { createSlice } from '@reduxjs/toolkit'

import { ICourseInitial } from '@store/features/course/course.types'
import { createCourseThunk, updateCourseThunk } from '@store/features/course/course.actions'

const payloadInitial = {
  guestFee: '',
  memberFee: '',
  courseName: '',
  emailAddress: '',
  courseAddress: '',
  proShopPhoneNumber: ''
}

const initialState: ICourseInitial = {
  execution: {
    status: '',
    isLoading: false
  },
  payload: { ...payloadInitial }
}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    comebackInitialState: (state) => {
      state.execution.status = ''
      state.execution.isLoading = false
      state.payload = payloadInitial
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCourseThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(updateCourseThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = 'updated'
      })
      .addCase(updateCourseThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
      .addCase(createCourseThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(createCourseThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = 'created'
      })
      .addCase(createCourseThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
  }
})

export const { comebackInitialState } = courseSlice.actions

export default courseSlice.reducer

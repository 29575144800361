import { COLORS } from '@collections/colors'

const MuiFormControl = {
  styleOverrides: {
    root: {
      width: '100%',

      '> .MuiFormLabel-root': {
        '&.Mui-focused': {
          color: 'unset !important'
        },
        '&.Mui-error': {
          color: `${COLORS.error} !important`
        }
      },

      '> .MuiInputBase-root': {
        '.MuiInputBase-input': {
          fontSize: '15px',
          background: '#fff',
          borderRadius: '10px',
          border: `1px solid ${COLORS.raisinBlack}`
        },
        '&.Mui-error > .MuiInputBase-input': {
          border: `1px solid ${COLORS.error} !important`
        }
      },
      '> .MuiFormHelperText-root': {
        color: `${COLORS.error} !important`
      }
    }
  }
}

export default MuiFormControl

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Section = styled(Box)(() => ({
  padding: '100px 86px',
  position: 'relative',

  '.MuiFormHelperText-root': {
    textAlign: 'center'
  }
}))

export const StyledIcon = styled('img')(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer'
}))

import { createSlice } from '@reduxjs/toolkit'

import { IMemberInitial } from '@store/features/member/member.types'
import { createMemberThunk, suggestMembersByKeyThunk } from '@store/features/member/member.actions'

const payloadInitial = {
  email: '',
  password: '',
  phone: '',
  name: '',
  number: ''
}

const initialState: IMemberInitial = {
  memberList: [],
  execution: {
    status: '',
    isLoading: false
  },
  payload: { ...payloadInitial }
}

const membersSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    comebackInitialState: (state) => {
      state.execution.status = ''
      state.execution.isLoading = false
      state.payload = payloadInitial
    },
    clearMembersList: (state) => {
      state.memberList = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMemberThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(createMemberThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = 'created'
      })
      .addCase(createMemberThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
      .addCase(suggestMembersByKeyThunk.fulfilled, (state, { payload }) => {
        state.memberList = payload || []
      })
      .addCase(suggestMembersByKeyThunk.rejected, (state) => {
        state.memberList = []
      })
  }
})

export const { comebackInitialState, clearMembersList } = membersSlice.actions

export default membersSlice.reducer

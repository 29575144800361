/* eslint-disable lines-between-class-members */
import AuthService from './AuthService'
import CoursesService from './CourseService'
import MemberService from './MemberService'
import TicketService from './TicketService'

class Api {
  ticket: TicketService
  member: MemberService
  course: CoursesService
  auth: AuthService

  constructor() {
    this.ticket = new TicketService()
    this.member = new MemberService()
    this.course = new CoursesService()
    this.auth = new AuthService()
  }
}

const API = new Api()

export default API

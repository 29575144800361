import { MouseEvent, useState } from 'react'
import { DialogAlertTicketFilterByDateViaState, TypeEnum } from './types'

export const filterByDateFlow = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isCalendar, setIsCalendar] = useState<DialogAlertTicketFilterByDateViaState>({
    isOpen: false,
    type: TypeEnum.EMPTY,
    values: {
      dateFrom: null,
      dateTo: null
    }
  })

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return { open, anchorEl, handleClose, handleClick, isCalendar, setIsCalendar }
}

import { useEffect, memo, Suspense, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Header from '@components/Header'
import Content from '@components/Hocs/Content'
import { RouteEnum } from '@appTypes/enums/global'
import { PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages'

import { savePrevPathname } from '@store/features/general/general.slice'
import usePrevious from '@hook/usePrevious'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { profileThunk } from '@store/features/auth/auth.actions'
import { resetAuthState } from '@store/features/auth/auth.slice'
import StorageManager from '@utils/storage-manager'
import { scrollToTop } from '@utils/scroll'
import { ContentHeader } from './components'
import { ContentLoader } from './styled'
import MobileHeader from './components/MobileMenu'

function LayoutPrivate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const user = useAppSelector((state) => state.auth.user)
  const execution = useAppSelector((state) => state.auth.execution)
  const { disableBackButton, prevPages } = useAppSelector((state) => state.general)
  const prevPathArray = usePrevious<string[]>(prevPages)
  const prevPath = usePrevious<string>(location.pathname)

  useEffect(() => {
    scrollToTop()

    if (prevPath && prevPages.length === prevPathArray.length) {
      dispatch(savePrevPathname({ pathname: prevPath }))
    }
  }, [location.pathname])

  useEffect(() => {
    if (user === null) {
      dispatch(profileThunk())
        .unwrap()
        .catch(() => {
          dispatch(resetAuthState())
          StorageManager.clearAuthInfo()
          navigate(PAGE_ROUTES_PUBLIC.SIGN_IN)
        })
    }
  }, [user])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <>
      <Header setIsOpen={setIsOpen} type={RouteEnum.PRIVATE} />
      <Content type={RouteEnum.PRIVATE}>
        <MobileHeader isOpen={isOpen} setIsOpen={setIsOpen} />
        <Suspense fallback={<ContentLoader />}>
          {execution.isLoading ? (
            <ContentLoader />
          ) : (
            <>
              {!disableBackButton ? <ContentHeader /> : null}
              <Outlet />
            </>
          )}
        </Suspense>
      </Content>
    </>
  )
}

export default memo(LayoutPrivate)

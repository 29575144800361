import { ITicket } from '@appTypes/global'
import { createSlice } from '@reduxjs/toolkit'

import { IInitialTicketState, TicketDates } from '@store/features/ticket/ticket.types'
import { getTicketThunk } from './ticket.actions'

const initialState: IInitialTicketState = {
  step: 1,
  ticket: {} as ITicket,
  execution: {
    status: '',
    isLoading: false
  },
  formState: {
    ticketCourseId: '',
    dates: [] as TicketDates[]
  }
}

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload
    },
    setFormCourseId(state, action) {
      state.formState.ticketCourseId = action.payload.id
    },
    setFormDate(state, action) {
      state.formState.dates = action.payload
    },
    resetFormDate(state) {
      state.formState.ticketCourseId = ''
      state.formState.dates = []
      state.step = 1
      state.ticket = {} as ITicket
    },
    updateTicketGroups(state, action) {
      state.ticket.groups = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(ticketUpdateStatusThunk.pending, () => {})
      // .addCase(ticketUpdateStatusThunk.fulfilled, () => {})
      // .addCase(ticketUpdateStatusThunk.rejected, () => {})
      // # # # # # #
      // .addCase(getTicketsThunk.pending, () => {})
      // .addCase(getTicketsThunk.fulfilled, () => {})
      // .addCase(getTicketsThunk.rejected, () => {})
      // # # # # # #
      // .addCase(getTicketThunk.pending, () => {})
      // .addCase(getTicketThunk.rejected, () => {})
      .addCase(getTicketThunk.fulfilled, (state, { payload }) => {
        state.ticket = payload
      })
  }
})

export const { setStep, setFormCourseId, setFormDate, resetFormDate, updateTicketGroups } =
  ticketSlice.actions

export default ticketSlice.reducer

import { styled } from '@mui/material/styles'

export const Form = styled('form')(({ theme }) => ({
  maxWidth: '530px',
  margin: '90px auto 10px auto',

  '.MuiTypography-h1': {
    textAlign: 'center'
  },

  '.MuiBox-root': {
    maxWidth: '300px',
    margin: 'auto auto',

    '.MuiFormControl-root': {
      ':nth-of-type(1)': {
        marginTop: '60px'
      },
      ':nth-of-type(2)': {
        marginTop: '20px'
      },
      ':nth-of-type(3)': {
        marginTop: '20px'
      }
    },
    '.MuiFormLabel-root': {
      marginBottom: '10px'
    }
  },

  '.MuiButton-root': {
    width: '100%',
    marginTop: '60px'
  },
  '.MuiTypography-body1': {
    maxWidth: '360px',
    margin: '20px auto 0 auto',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center'
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '335px',

    '.MuiBox-root': {
      maxWidth: 'unset',

      '.MuiFormControl-root': {
        ':nth-of-type(1)': {
          marginTop: '40px'
        }
      }
    },

    '.MuiButton-root': {
      marginTop: '40px'
    }
  }
}))

import { FC, HTMLAttributes, SyntheticEvent, useEffect, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'

import { range } from '@utils/array'
import { useAppSelector } from '@store/hooks'
import { IMember } from '@store/features/member/member.types'
import {
  AutocompleteTitle,
  SelectMembersContainer,
  Selects,
  SelectsRow,
  AutocompleteTextField,
  OptionMenuItem
} from './styled'
import {
  ISelectGroupsFormValues,
  ISelectMembersFormValues,
  MemberOptionType
} from '../../../../types'
import {
  filterOptions,
  getOptionLabel,
  isOptionEqualToValue,
  updateMembersInLocalStorageGroups
} from '../../../../helpers/selectMembersHelpers'

interface SelectMembersStepProps {
  groupId: string
  isVisible: 'isVisible' | ''
  defaultValues?: ISelectMembersFormValues
  onUpdate: (groups: ISelectGroupsFormValues[]) => void
  members: { data: IMember[]; total: number } | undefined
}

const SelectMembersStep: FC<SelectMembersStepProps> = ({
  onUpdate,
  isVisible,
  defaultValues,
  groupId,
  members
}) => {
  // @ts-ignore
  const { userInfo = {} } = useAppSelector(({ auth }) => auth)

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
    setValue
  } = useForm<ISelectMembersFormValues>({
    defaultValues
  })

  const { fields, remove, update } = useFieldArray({
    control,
    name: 'members',
    keyName: 'customID'
  })

  const membersValues = getValues().members

  const [first, second, third, fourth] = membersValues

  const membersList = useMemo(() => {
    return (
      members?.data.map(({ id, fullName }: any) => {
        return { id, fullName, isMember: true }
      }) || []
    )
  }, [members])

  const secondInputMembersList = useMemo(() => {
    return membersList.filter((a: any) => a.id !== first?.id)
  }, [first?.id, membersList])

  const thirdInputMembersList = useMemo(() => {
    return secondInputMembersList.filter((a: any) => a.id !== second?.id)
  }, [second?.id, secondInputMembersList])

  const fourthInputMembersList = useMemo(() => {
    return thirdInputMembersList.filter((a: any) => a.id !== third?.id)
  }, [third?.id, thirdInputMembersList])

  const submitHandler: SubmitHandler<{ members: MemberOptionType[] }> = (data) => {
    console.log('SubmitHandler: ', submitHandler)
    // onNext(data);
  }

  const handleRemoveFields = (index: number) => {
    if (index === 0) {
      setValue('members', [])
    } else {
      remove(range(index, fields.length))
    }
  }

  const handleSelectChange =
    (index: number) => (event: SyntheticEvent, newValue: MemberOptionType | null | string) => {
      if (!newValue) {
        return handleRemoveFields(index)
      }

      remove(range(index + 1, fields.length))

      if (typeof newValue === 'string') {
        update(index, {
          id: Math.random().toString(),
          fullName: newValue,
          isMember: false
        })
      } else if (newValue && newValue.inputValue) {
        update(index, {
          id: Math.random().toString(),
          fullName: newValue.inputValue,
          isMember: false
        })
      } else {
        update(index, newValue)
      }
    }

  const getInputTitle = (option: MemberOptionType) => {
    if (!option) {
      return 'Member or Guest Name'
    }

    return option.isMember ? 'Member' : 'Guest'
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    { id, fullName }: MemberOptionType
  ) => {
    const isCurrentMember = userInfo.member?.id === id

    return (
      <OptionMenuItem {...props} value={id} key={id}>
        <Typography>{fullName}</Typography>

        {isCurrentMember && <Typography className='currentMemberText'>(Me)</Typography>}
      </OptionMenuItem>
    )
  }

  useEffect(() => {
    updateMembersInLocalStorageGroups({
      groupId,
      members: membersValues,
      onUpdate
    })
  }, [membersValues])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <SelectMembersContainer $isVisible={isVisible} onSubmit={handleSubmit(submitHandler)}>
      <Selects>
        <SelectsRow>
          <Box>
            <AutocompleteTitle isMember hasValue={!!first}>
              Member
            </AutocompleteTitle>

            <Autocomplete
              value={first || null}
              onChange={handleSelectChange(0)}
              isOptionEqualToValue={isOptionEqualToValue}
              id='member-1'
              options={membersList}
              getOptionLabel={(option) => option.fullName}
              renderOption={renderOption}
              renderInput={(params) => (
                <AutocompleteTextField
                  {...params}
                  placeholder='Name'
                  error={!!errors?.members?.message}
                  helperText={errors?.members?.message}
                />
              )}
              forcePopupIcon={false}
            />
          </Box>

          <Box>
            <AutocompleteTitle isMember={second?.isMember} hasValue={!!second}>
              {getInputTitle(second)}
            </AutocompleteTitle>
            <Autocomplete
              value={second || null}
              disabled={!first}
              onChange={handleSelectChange(1)}
              isOptionEqualToValue={isOptionEqualToValue}
              id='member-2'
              options={secondInputMembersList}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={(params) => <AutocompleteTextField {...params} placeholder='Name' />}
              filterOptions={filterOptions}
              selectOnFocus
              clearOnBlur
              freeSolo
            />
          </Box>
        </SelectsRow>

        <SelectsRow>
          <Box>
            <AutocompleteTitle isMember={third?.isMember} hasValue={!!third}>
              {getInputTitle(third)}
            </AutocompleteTitle>
            <Autocomplete
              value={third || null}
              disabled={!second}
              onChange={handleSelectChange(2)}
              isOptionEqualToValue={isOptionEqualToValue}
              id='member-3'
              options={thirdInputMembersList}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={(params) => <AutocompleteTextField {...params} placeholder='Name' />}
              filterOptions={filterOptions}
              selectOnFocus
              clearOnBlur
              freeSolo
            />
          </Box>

          <Box>
            <AutocompleteTitle isMember={fourth?.isMember} hasValue={!!fourth}>
              {getInputTitle(fourth)}
            </AutocompleteTitle>
            <Autocomplete
              value={fourth || null}
              disabled={!third}
              onChange={handleSelectChange(3)}
              isOptionEqualToValue={isOptionEqualToValue}
              id='member-4'
              options={fourthInputMembersList}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={(params) => <AutocompleteTextField {...params} placeholder='Name' />}
              filterOptions={filterOptions}
              selectOnFocus
              clearOnBlur
              freeSolo
            />
          </Box>
        </SelectsRow>
      </Selects>
    </SelectMembersContainer>
  )
}

SelectMembersStep.defaultProps = {
  defaultValues: {
    members: []
  }
}

export default SelectMembersStep

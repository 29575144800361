import { AxiosResponse } from 'axios'

import { http } from '@api/RequestApi'
import { ITicket } from '@appTypes/global'
import {
  ApproveTicketPayload,
  CreateTicketRequest,
  IGetTicketsResponse,
  IUpdateStatus
} from '@store/features/ticket/ticket.types'
import { BaseAPI } from './ApiServices'

class TicketService extends BaseAPI {
  private prefix = '/club/tickets'

  constructor() {
    super()
  }

  public async getTicketsExport(url: string) {
    const response = await http.get<void, { data: string }>(`${this.prefix}/export${url}`)

    return response
  }

  public async getTicket(id: string) {
    const response = await http.get<void, AxiosResponse<{ data: ITicket }>>(
      `${this.prefix}/${id}/info`
    )

    return response?.data
  }

  public async getTickets(url: string) {
    BaseAPI.isUserExist()

    const response = await http.get<void, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}?${url}`
    )
    return response?.data
  }

  public async updateTicket(id: string, data: any) {
    const response = await http.put<
      { date: string; courseId: string },
      AxiosResponse<IGetTicketsResponse>
    >(`${this.prefix}/${id}/counter`, data)

    return response?.data
  }

  public async updateStatus(id: string, data: IUpdateStatus) {
    const response = await http.put<IUpdateStatus, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}/${id}/status`,
      data
    )

    return response?.data
  }

  public async finishTicket(id: string) {
    const response = await http.put<void, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}/${id}/finish`
    )

    return response?.data
  }

  public async createTicket(data: CreateTicketRequest) {
    const response = await http.post<CreateTicketRequest, AxiosResponse<any>>(
      `${this.prefix}`,
      data
    )

    return response?.data
  }

  public async updateOwnTicket({ data, id }: { id: string; data: CreateTicketRequest }) {
    const response = await http.put<CreateTicketRequest, AxiosResponse<any>>(
      `${this.prefix}/${id}`,
      data
    )

    return response?.data
  }

  public async approveTicket({ ticketId, payload }: ApproveTicketPayload) {
    const response = await http.put<any, AxiosResponse<any>>(
      `${this.prefix}/${ticketId}/approve`,
      payload
    )

    return response?.data
  }
}

export default TicketService

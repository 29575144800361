import { Dispatch, SetStateAction, memo } from 'react'

import { ReactComponent as GroupsCloseIcon } from '@svgs/groupCloseIcon.svg'
import { ISelectGroupsFormValues } from '@pages/CreateTicket/book-ticket/types'
import { Container, Line, Wrapper, Item } from './styled'

interface GroupToggleProps {
  active: number
  setActive: Dispatch<SetStateAction<number>>
  groups: ISelectGroupsFormValues[]
  onCreate: () => void
  onDelete: (groupId: string) => void
}

const GroupToggle = ({ onDelete, active, setActive, groups, onCreate }: GroupToggleProps) => {
  return (
    <Container>
      <Wrapper>
        {groups.map((group, index) => (
          <Item key={group.id} $isActive={active === index ? 'active' : ''}>
            <p onClick={() => setActive(index)}>Group {index + 1}</p>
            {active === index && groups.length > 1 && (
              <GroupsCloseIcon
                onClick={() => {
                  setActive(0)
                  onDelete(group.id)
                }}
              />
            )}
          </Item>
        ))}
        <Item
          $isActive=''
          onClick={() => {
            onCreate()
          }}
        >
          + Add Group
        </Item>
      </Wrapper>
      <Line />
    </Container>
  )
}

export default memo(GroupToggle)

import { Box, css, styled } from '@mui/material'

export const Container = styled(Box)`
  position: relative;
  margin-top: 55px;
  overflow: scroll;
  border-bottom: 1px solid #d7d7d7;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`

export const Line = styled(Box)`
  position: absolute;
  width: 100vw;
  max-width: 1360px;
  height: 1px;
  background: #ddd;
  left: -40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`

export const Wrapper = styled(Box)`
  width: max-content;

  margin: 0 auto;
  display: flex;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: calc(100vw - 40px);
      gap: 40px;
      overflow-x: scroll;
    }
  `}
`

export const Item = styled('div')<{ $isActive: 'active' | '' }>`
  min-width: 156px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;

  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  ${({ $isActive }) => {
    return (
      $isActive &&
      css`
        p {
          color: #047e50;
        }
        border-bottom: 3px solid #047e50;
      `
    )
  }};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      min-width: 140px;
      gap: 40px;
      overflow-x: scroll;
    }
  `}
`

export const signInFields = [
  { type: 'text', label: 'Email', name: 'email', placeholder: 'Your email' },
  { type: 'password', label: 'Password', name: 'password', placeholder: 'Your password' }
]

export const createCourseFields = [
  { type: 'text', label: 'Course Name', name: 'courseName', placeholder: 'Course Name' },
  {
    type: 'number',
    label: 'Pro Shop Phone Number',
    name: 'proShopPhoneNumber',
    placeholder: 'Pro Shop Phone Number'
  },
  { type: 'text', label: 'Course Address', name: 'courseAddress', placeholder: 'Course Address' },
  { type: 'text', label: 'Email Address', name: 'emailAddress', placeholder: 'Email Address' },
  { type: 'number', label: 'Member Fee', name: 'memberFee', placeholder: 'Member Fee' },
  { type: 'number', label: 'Guest Fee', name: 'guestFee', placeholder: 'Guest Fee' },
  {
    type: 'textarea',
    label: 'Course Notes ',
    name: 'courseNotes',
    placeholder: 'Enter Messages '
  }
]
export const addMemberFields = [
  { type: 'text', label: 'Name', name: 'name', placeholder: "Members's Name" },
  { type: 'text', label: 'Email Address', name: 'email', placeholder: "Members's email address" },
  {
    type: 'number',
    label: 'Phone number',
    name: 'phone',
    placeholder: "Members's Phone Number"
  },
  { type: 'password', label: 'Password', name: 'password', placeholder: "Members's password" },
  { type: 'text', label: 'Member number', name: 'number', placeholder: "Members's ID" }
]

export const forgotPasswordFields = [
  { type: 'text', label: 'Email', name: 'email', placeholder: 'Please enter your Email' }
]

export const changePasswordFields = [
  { type: 'password', label: 'Current Password', name: 'current', placeholder: 'Current Password' },
  { type: 'password', label: 'New Password', name: 'new', placeholder: 'New password' },
  { type: 'password', label: 'Confirm New Password', name: 'confirm', placeholder: 'Enter your new password agains' }
]

export const recoverPasswordFields = [
  { type: 'password', label: 'New Password', name: 'newPassword', placeholder: 'New password' },
  {
    type: 'password',
    label: 'Confirm Password',
    name: 'confirmPassword',
    placeholder: 'Enter your password again'
  }
]

import { COLORS } from '@collections/colors'

const MuiCheckbox = {
  styleOverrides: {
    root: {
      padding: '0',
      '.MuiSvgIcon-root': {
        // stroke: COLORS.raisinBlack,
        color: COLORS.black
      }
    }
  }
}

export default MuiCheckbox

import { COLORS } from '@collections/colors'
import { Button } from '@components/common'
import { styled } from '@mui/material/styles'

export const ConfirmCancelationButton = styled(Button)(() => ({
  color: COLORS.raisinBlack,
  width: '180px !important',
  fontWeight: '600',
  fontSize: '13px',
  lineHeight: '16px'
}))

import { PaletteOptions } from '@mui/material'

import { COLORS } from '@collections/colors'

export const palette: PaletteOptions = {
  primary: {
    light: '#828282',
    main: '#201F21'
  },
  secondary: {
    main: '#219653'
  },
  thirdly: {
    main: '#EB5757'
  },
  error: {
    main: COLORS.error
  },
  text: {
    primary: COLORS.raisinBlack
  },
  background: {
    default: COLORS.gray,
    paper: COLORS.white
  }
}

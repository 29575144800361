import { COLORS } from '@collections/colors'

const MuiButton = {
  styleOverrides: {
    root: {
      minWidth: 'max-content',
      width: '100%',
      height: '51px',
      textTransform: 'none',
      background: COLORS.raisinBlack2,
      border: `1px solid ${COLORS.white}`,
      borderRadius: '8px',
      color: COLORS.white,
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      '&:hover': {
        background: COLORS.raisinBlack2,
        boxShadow: 'none'
      },
      '&.Mui-disabled': {
        background: COLORS.disabled,
        color: COLORS.white
      }
    } as any,
    containedPrimary: {
      height: '40px',
      background: COLORS.green,
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: COLORS.green,
        boxShadow: 'none'
      }
    },
    containedSecondary: {
      height: '40px',
      color: COLORS.white,
      boxShadow: 'none',
      background: COLORS.red,
      '&:hover': {
        background: COLORS.red
      }
    },
    outlinedPrimary: {
      boxShadow: 'none',
      height: '40px',
      border: `1px solid ${COLORS.grayButton}`,
      borderColor: COLORS.grayButton,
      background: COLORS.white,
      color: COLORS.grayButton,
      '&:hover': {
        background: COLORS.white,
        border: `1px solid ${COLORS.grayButton}`
      }
    },
    outlinedSecondary: {
      height: '40px',
      color: COLORS.red,
      boxShadow: 'none',
      background: COLORS.white,
      border: `1px solid ${COLORS.red}`,
      '&:hover': {
        border: `1px solid ${COLORS.red}`,
        color: COLORS.red,
        background: COLORS.white
      }
    }
  }
}

export default MuiButton

import { Box } from '@mui/material'

import { styled } from '@mui/material/styles'

export const StyledIcon = styled('img')(() => ({
  width: '13px'
}))

export const AutocompleteWrapper = styled(Box)(({ theme }) => ({
  width: '180px',

  '.MuiFormControl-root': {
    height: '34px !important'
  },

  '.MuiAutocomplete-popper': {
    height: '37px !important'
  },

  input: {
    maxHeight: '33px !important'
  },

  fieldset: {
    maxHeight: '33px !important'
  },
  [theme.breakpoints.down('md')]: {
    '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: '0px !important',
      top: '0px !important',
      maxHeight: '33px !important',
      paddingRight: '5px',

      button: {
        maxHeight: '33px !important',
        justifyContent: 'flex-end !important'
      }
    }
  }
}))

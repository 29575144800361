import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'
import { CreateTicketRequest } from '@pages/CreateTicket/book-ticket/types'
import { IMakeTicketCounter, ApproveTicketPayload, UpdateTicketPayload } from './ticket.types'

export const ticketUpdateStatusThunk = createAsyncThunk(
  'ticket/update',
  async ({ ticketId, payload }: UpdateTicketPayload, { rejectWithValue }) => {
    try {
      await Api.ticket.updateStatus(ticketId, payload)

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const ticketApproveThunk = createAsyncThunk(
  'ticket/approve',
  async (data: ApproveTicketPayload, { rejectWithValue }) => {
    try {
      await Api.ticket.approveTicket(data)

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const makeTicketCounterThunk = createAsyncThunk(
  'ticket/update',
  async ({ ticketId, payload }: IMakeTicketCounter, { rejectWithValue }) => {
    try {
      await Api.ticket.updateTicket(ticketId, payload)

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTicketsThunk = createAsyncThunk(
  'tickets/get',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.getTickets(url)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTicketsExportThunk = createAsyncThunk(
  'tickets/get-export',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.getTicketsExport(url)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTicketThunk = createAsyncThunk(
  'ticket/get',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.getTicket(id)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fireFinishTicket = createAsyncThunk(
  'ticket-finish/put',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.finishTicket(id)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createTicketThunk = createAsyncThunk(
  'ticket-create/post',
  async ({ data }: { data: CreateTicketRequest }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.createTicket(data)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateOwnTicketThunk = createAsyncThunk(
  'update-own-ticket/post',
  async (data: { id: string; data: CreateTicketRequest }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.updateOwnTicket(data)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

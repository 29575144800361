import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  maxWidth: '450px',
  minHeight: '354px',
  borderRadius: '10px',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray87}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

export const Body = styled(Box)<{ page: string }>(({ theme, page }) => ({
  padding: page === 'TicketsHistory' ? '20px' : '20px 20px 20px 20px',
  width: '100%',
  height: '100%',
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '90px',
  // gridTemplateRows: '75px',

  '> .MuiBox-root': {
    h5: {
      marginBottom: '5px'
    },
    ':last-child': {
      gridColumn: page === 'TicketsHistory' ? '1/3' : 'unset'
    }
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    gap: '15px',
    flexDirection: 'column',

    h5: {
      marginBottom: '5px'
    }
  }
}))

export const StyledTypographyDateValue = styled(Typography)(({ theme }) => ({
  color: '#201F21',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '140%',

  [theme.breakpoints.down('md')]: {
    span: {
      span: {
        marginLeft: '10px'
      },
      br: {
        display: 'none'
      }
    }
  }
}))

export const Footer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  padding: '20px 0',
  maxHeight: '81px',
  height: '81px',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${COLORS.gray87}`,

  '.MuiButtonBase-root': {
    width: '104px',
    height: '40px',
    marginLeft: '10px',

    ':nth-ot-type(2)': {
      marginLeft: '0'
    }
  },

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    gap: '12px',
    padding: '20px 10px',

    '.MuiButtonBase-root': {
      padding: '0px',
      margin: '0px',
      width: '90px',
      fontSize: '13px'
    }
  }
}))

export const StyledStatusTypography = styled(Typography)<{ color: string }>(({ color }) => ({
  color
}))

export const MembersWrapper = styled(Box)(() => ({
  '>.MuiTypography-body1': {
    whiteSpace: 'nowrap',
    width: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export const CoursesTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
  -webkit-box-orient: vertical;
`

export const InvalidText = styled(Typography)`
  position: absolute;
  top: -20px;
  left: 20px;
`

export const MemberId = styled('span')`
  display: block;
  color: #d7d7d7;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  cursor: pointer;
`

export const StyledIcon = styled('img')(() => ({
  position: 'absolute',
  top: '21px',
  right: '21px',
  width: '12px',
  height: '12px',
  cursor: 'pointer'
}))

export const StyledEditIcon = styled('img')<{ openTickets: boolean }>(({ openTickets }) => ({
  position: 'absolute',
  top: '20px',
  right: openTickets ? '50px' : '20px',
  cursor: 'pointer'
}))

export const SeeMoreWrapper = styled(Box)(() => ({
  padding: '0px 20px 20px 20px',

  span: {
    cursor: 'pointer',
    color: '#047E50',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal'
  },

  display: 'flex',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'left',

  img: {
    cursor: 'pointer'
  }
}))

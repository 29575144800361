import { FC, useEffect, useState } from 'react'

import PageTitle from '@ui/PageTitle'
import PageSubtitle from '@ui/PageSubtitle'

// import { useGetMembersQuery } from '../../../../api/bookTicket.api'
import API from '@services'
import { IMember } from '@store/features/member/member.types'
import StepperButtons from '../StepperButtons'
import { ISelectGroupsFormValues } from '../../../../types'
import SelectMembersStep from '../SelectMembersStep'
import { GroupToggle } from './components'
import { SelectMembersContainer, ValidationMessage } from './styled'

interface SelectGroupsStepProps {
  defaultValues: ISelectGroupsFormValues[]
  onBack: () => void
  onCreate: () => void
  onDelete: (groupId: string) => void
  onNext: (data: ISelectGroupsFormValues[]) => void
  onUpdate: (groups: ISelectGroupsFormValues[]) => void
}

const SelectGroupsStep: FC<SelectGroupsStepProps> = ({
  onUpdate,
  onDelete,
  onCreate,
  onNext,
  onBack,
  defaultValues
}) => {
  const [active, setActive] = useState<number>(0)
  const [message, setMessage] = useState('')
  const [members, setMembers] = useState<{ data: IMember[]; total: number } | undefined>()

  const submitHandler = (event: any) => {
    event.preventDefault()

    let isValid = true

    defaultValues.forEach(({ members }) => {
      const isMemberExist = members?.find((member) => member?.isMember)

      if (!isMemberExist) {
        isValid = false
      }
    })

    if (isValid) {
      onNext(defaultValues)
      setMessage('')
    } else {
      setMessage('Every group should has one member or guest.')
    }
  }

  const getMembersHandler = async () => {
    const { data } = await API.member.getMembers('')

    setMembers(data)
  }

  useEffect(() => {
    getMembersHandler()
  }, [])

  return (
    <SelectMembersContainer component='form' onSubmit={submitHandler}>
      <PageTitle textAlign='center'>Enter your group names</PageTitle>
      <PageSubtitle textAlign='center'>Select members, add your guests.</PageSubtitle>

      <GroupToggle
        active={active}
        groups={defaultValues}
        onCreate={onCreate}
        setActive={setActive}
        onDelete={onDelete}
      />
      {message && <ValidationMessage>{message}</ValidationMessage>}
      {defaultValues?.map((group, index) => (
        <SelectMembersStep
          members={members}
          onUpdate={onUpdate}
          key={group.id}
          isVisible={active === index ? 'isVisible' : ''}
          groupId={group.id}
          defaultValues={group}
        />
      ))}

      <StepperButtons
        backButtonProps={{
          onClick: onBack
        }}
        nextButtonProps={{
          type: 'submit'
        }}
      />
    </SelectMembersContainer>
  )
}

export default SelectGroupsStep

import { FiltersOrderValuesEnum, FiltersParamsKeysEnum } from '@appTypes/enums/filters'

export enum FiltersViewEnum {
  addAMember = 'addAMember',
  createACourse = 'createACourse',
  memberSearch = 'memberSearch',
  createTicketButton = 'createTicketButton',
  sortedByTeeTime = 'sortedByTeeTime',
  sortedByDate = 'sortedByDate',
  filterByDate = 'filterByDate',
  allMembersAndAllCourses = 'allMembersAndAllCourses',
  approvedButton = 'approvedButton',
  declinedButton = 'declinedButton',
  canceledButton = 'canceledButton'
}

export interface IFiltersOrders {
  desktop: FiltersViewEnum[]
  mobile: FiltersViewEnum[]
}

export const ticketsCoursesFiltersOrders = {
  mobile: [FiltersViewEnum.allMembersAndAllCourses, FiltersViewEnum.createACourse],
  desktop: [FiltersViewEnum.allMembersAndAllCourses]
}

export const ticketsMembersFiltersOrders = {
  mobile: [FiltersViewEnum.allMembersAndAllCourses, FiltersViewEnum.addAMember],
  desktop: [FiltersViewEnum.allMembersAndAllCourses]
}

export const openTicketsFiltersOrders = {
  mobile: [
    FiltersViewEnum.memberSearch,
    FiltersViewEnum.createTicketButton,
    FiltersViewEnum.sortedByTeeTime,
    FiltersViewEnum.sortedByDate,
    FiltersViewEnum.filterByDate
  ],
  desktop: [
    FiltersViewEnum.sortedByTeeTime,
    FiltersViewEnum.sortedByDate,
    FiltersViewEnum.filterByDate,
    FiltersViewEnum.memberSearch
  ]
}

export const ticketsHistoryFiltersOrders = {
  mobile: [
    FiltersViewEnum.memberSearch,
    FiltersViewEnum.createTicketButton,
    FiltersViewEnum.sortedByTeeTime,
    FiltersViewEnum.sortedByDate,
    FiltersViewEnum.filterByDate,
    FiltersViewEnum.approvedButton,
    FiltersViewEnum.declinedButton,
    FiltersViewEnum.canceledButton
  ],
  desktop: [
    FiltersViewEnum.sortedByTeeTime,
    FiltersViewEnum.sortedByDate,
    FiltersViewEnum.filterByDate,
    FiltersViewEnum.memberSearch
  ]
}

const sortedByTeeTime = {
  id: 1,
  type: 'simple',
  key: FiltersParamsKeysEnum.orderBy,
  value: FiltersOrderValuesEnum.date,
  title: 'Sorted by Tee Time'
}

const sortedByDate = {
  id: 2,
  type: 'simple',
  key: FiltersParamsKeysEnum.orderBy,
  value: FiltersOrderValuesEnum.createdAt,
  title: 'Sorted by Date'
}

const filterByDate = {
  id: 3,
  type: 'simple',
  key: `${FiltersParamsKeysEnum.dateFrom}+${FiltersParamsKeysEnum.dateTo}`,
  value: '',
  title: 'Filter By Date'
}

const createTicketButton = {
  id: 4,
  type: FiltersViewEnum.createTicketButton,
  key: '',
  value: '',
  title: '...'
}

const memberSearch = {
  id: 5,
  type: FiltersViewEnum.memberSearch,
  key: '',
  value: '',
  title: '...'
}

const approvedButton = {
  id: 6,
  type: FiltersViewEnum.approvedButton,
  key: ``,
  value: '',
  title: ''
}

const declinedButton = {
  id: 7,
  type: FiltersViewEnum.declinedButton,
  key: ``,
  value: '',
  title: ''
}

const canceledButton = {
  id: 8,
  type: FiltersViewEnum.canceledButton,
  key: ``,
  value: '',
  title: ''
}

const allMembersAndAllCourses = {
  id: 9,
  type: FiltersViewEnum.allMembersAndAllCourses,
  key: ``,
  value: '',
  title: ''
}

const createACourse = {
  id: 10,
  type: FiltersViewEnum.createACourse,
  key: ``,
  value: '',
  title: ''
}

const addAMember = {
  id: 11,
  type: FiltersViewEnum.addAMember,
  key: ``,
  value: '',
  title: ''
}

export const collection = {
  sortedByTeeTime,
  sortedByDate,
  filterByDate,
  memberSearch,
  createTicketButton,
  approvedButton,
  declinedButton,
  canceledButton,
  allMembersAndAllCourses,
  createACourse,
  addAMember
}

import { forwardRef, memo, Ref } from 'react'

import InputBase, { InputBaseProps } from '@mui/material/InputBase'

interface IInput extends InputBaseProps {
  key?: string
  type?: string
  name?: string
  placeholder?: string
  register?: any
  error?: any
}

const Input = (props: IInput, ref: Ref<unknown>) => {
  if (props.type === 'number' || props.type === 'numeric') {
    return (
      <InputBase
        ref={ref}
        {...props}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9.]*'
        }}
      />
    )
  }

  return <InputBase ref={ref} {...props} />
}

export default memo(forwardRef(Input))

import { Button, LinkTo } from '@components/common'
import { logoutThunk } from '@store/features/auth/auth.actions'
import { useAppDispatch } from '@store/hooks'
import { Dispatch, SetStateAction } from 'react'
import useHome from './useHome'
import { Section, BoxItem, Wrapper, StyledTypography, TopSection } from './styled'

const HomeViewMobileVersion = ({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { pages, ownerName } = useHome()
  const dispatch = useAppDispatch()

  return (
    <Wrapper>
      <TopSection>
        <StyledTypography>Welcome, {ownerName}!</StyledTypography>
        <Button
          onClick={() => {
            dispatch(logoutThunk())
          }}
        >
          Logout
        </Button>
      </TopSection>
      <Section>
        {pages?.map((item) => {
          return (
            <LinkTo key={item.title} href={item.link + item.params}>
              <BoxItem onClick={() => setIsOpen(false)}>{item.title}</BoxItem>
            </LinkTo>
          )
        })}
      </Section>
    </Wrapper>
  )
}

export default HomeViewMobileVersion

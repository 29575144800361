import { CSSInterpolation } from '@mui/material'

export enum FontFamilyNames {
  InterLight = 'Inter Light',
  Inter = 'Inter',
  InterBold = 'Inter Bold',
  InterExtraBold = 'Inter Extra Bold',
  SourceSerifPro = 'Source Serif Pro'
}

type FontFamily = typeof FontFamilyNames

type Values = FontFamily[keyof FontFamily]

export interface ThemeFont {
  fontFamily: Values[]
  fontFaces: CSSInterpolation
}

import { memo } from 'react'

import { getDateFromFirstGroup } from '@utils/time'

import seeMoreArrow from '@assets/svgs/seeMoreArrow.svg'
import type { ITicket } from '@appTypes/global'
import { setPopUpData } from '@store/features/general/general.slice'
import { useAppDispatch } from '@store/hooks'
import { DialogAlertKeysEnum } from '@appTypes/enums/global'
import TicketBoxButtons from './components/TicketBoxButtons'
import IsFinishedItem from './components/IsFinishedItem'
import OpenTicketsView from './components/Views/open'
import HistoryTicketsView from './components/Views/history'
import { Section, Footer, InvalidText, SeeMoreWrapper } from './styled'

interface ITicketBox {
  ticket: ITicket
  page: 'OpenTickets' | 'TicketsHistory'
}

const TicketBox = ({ page, ticket }: ITicketBox) => {
  const dispatch = useAppDispatch()
  const { id, status, groups, isFinished, offerStatus, availability, ticketCourses } = ticket

  const invalid = !groups?.length || !ticketCourses?.length
  const date = getDateFromFirstGroup(ticket.groups)

  return (
    <Section>
      <IsFinishedItem page={page} ticket={ticket} />
      {page === 'OpenTickets' ? (
        <OpenTicketsView page={page} date={date} ticket={ticket} />
      ) : (
        <HistoryTicketsView page={page} date={date} ticket={ticket} />
      )}
      <SeeMoreWrapper>
        <span
          onClick={() => {
            dispatch(
              setPopUpData({
                popUpType: DialogAlertKeysEnum.ticketSeeMore,
                popUpId: id
              })
            )
          }}
        >
          See More
        </span>
        <img src={seeMoreArrow} alt='See More Arrow' loading='lazy' />
      </SeeMoreWrapper>
      <Footer>
        {invalid ? <InvalidText>The ticket is missing information .</InvalidText> : null}
        <TicketBoxButtons
          id={id}
          page={page}
          date={date.toISOString()}
          status={status}
          isFinished={isFinished}
          offerStatus={offerStatus}
          availability={availability}
          coursesLength={ticketCourses?.length || 0}
          membersLength={groups?.length || 0}
        />
      </Footer>
    </Section>
  )
}

export default memo(TicketBox)

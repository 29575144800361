/* eslint-disable newline-per-chained-call */
import { memo, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button } from '@components/common'
import { FiltersContext } from '@contexts/filters'
import { FiltersParamsKeysEnum } from '@appTypes/enums/filters'
import { IDefaultFilterByDate } from '../../types'
import { useStaticDateFiltersSection } from './useStaticDateFiltersSection'
import { FromTo } from './components'
import { Section } from '../../styled'

interface IStaticDateFiltersSection extends IDefaultFilterByDate {}

const StaticDateFiltersSection = ({ isCalendar, setIsCalendar }: IStaticDateFiltersSection) => {
  const [searchParams] = useSearchParams()
  const { dateFrom, dateTo } = FiltersParamsKeysEnum
  const { collectQueryParams } = useContext(FiltersContext)
  const { startDates, validateBeforeUse } = useStaticDateFiltersSection({ setIsCalendar })

  return (
    <Section>
      <FromTo isCalendar={isCalendar} setIsCalendar={setIsCalendar} />
      {startDates.map(({ title, value, fromTo }) => (
        <p
          key={title}
          onClick={() => {
            validateBeforeUse({ value, fromTo })
          }}
        >
          {title}
        </p>
      ))}
      {searchParams.has(dateFrom) || searchParams.has(dateTo) ? (
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={() => {
            collectQueryParams({ [dateFrom]: '' })
            collectQueryParams({ [dateTo]: '' })
            setIsCalendar((prev) => ({ ...prev, values: { dateFrom: null, dateTo: null } }))
          }}
        >
          Clear
        </Button>
      ) : null}
    </Section>
  )
}

export default memo(StaticDateFiltersSection)

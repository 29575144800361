import { ISelectOption } from '@appTypes/global'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'

import { StyledMuiSelect, StyledMuiInputLabel } from './styled'

interface ISelect {
  title: string
  options: ISelectOption[]
  selectedOption: ISelectOption | null
  setState: (option: ISelectOption) => void
}

const Select = ({ title, options, selectedOption, setState }: ISelect) => {
  return (
    <Box>
      <StyledMuiInputLabel shrink htmlFor={`select-${title}`}>
        {title}
      </StyledMuiInputLabel>
      <StyledMuiSelect
        id={`select-${title}`}
        value={selectedOption}
        defaultValue={selectedOption}
        renderValue={(selected: any) => {
          return selected?.name
        }}
        onChange={(event) => {
          const found = options.find((item) => item.value === event.target.value)

          setState(found || options[0])
        }}
      >
        {options?.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          )
        })}
      </StyledMuiSelect>
    </Box>
  )
}

export default Select

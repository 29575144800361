import { useEffect } from 'react'

import Menu from '@mui/material/Menu'

import { TypeEnum } from './types'
import { DatePickerSection, StaticDateFiltersSection } from './components'
import { filterByDateFlow } from './filterByDateFlow'

const DropDownFilterByDate = ({ children }: any) => {
  const { open, anchorEl, isCalendar, handleClose, handleClick, setIsCalendar } = filterByDateFlow()

  useEffect(() => {
    let time: any

    if (!open) {
      time = setTimeout(() => {
        setIsCalendar((prev) => ({ ...prev, type: TypeEnum.EMPTY, isOpen: false }))
      }, 300)
    }

    return () => {
      clearTimeout(time)
    }
  }, [open])

  return (
    <div>
      <div onClick={handleClick}>{children}</div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {isCalendar.type !== TypeEnum.EMPTY ? (
          <DatePickerSection isCalendar={isCalendar} setIsCalendar={setIsCalendar} />
        ) : (
          <StaticDateFiltersSection isCalendar={isCalendar} setIsCalendar={setIsCalendar} />
        )}
      </Menu>
    </div>
  )
}

export default DropDownFilterByDate

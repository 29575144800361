import { Box } from '@mui/material'
import { LinkTo } from '@components/common'

interface ISelectSimple {
  id: string
  link: string
  title: string
  params: string
  activePage: string
}

const SelectSimple = ({ id, link, title, params, activePage }: ISelectSimple) => {
  return (
    <Box key={id}>
      <LinkTo href={link + params} key={title}>
        {activePage === link ? <b>{title}</b> : title}
      </LinkTo>
    </Box>
  )
}

export default SelectSimple

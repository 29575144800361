import { COLORS } from '@collections/colors'
import { styled } from '@mui/material/styles'

import Input from '../Input'

export const StyledInput = styled(Input)(() => ({
  width: '180px',
  height: '36px',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '10px',
  fontSize: '13px',
  lineHeight: '140%',

  '> input': {
    maxHeight: '34px',
    padding: '13px 40px 13px 13px'
  },
  img: {
    position: 'absolute',
    cursor: 'pointer',
    width: '19px',
    height: '14px',
    right: '12px',
    top: '11spx'
  }
}))

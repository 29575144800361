import { createTheme } from '@mui/material/styles'
import { palette } from '@theme/palette'
import { breakpoints } from '@theme/breakpoints'

import {
  MuiButton,
  MuiSelect,
  MuiCheckbox,
  MuiInputBase,
  MuiTypography,
  MuiInputLabel,
  MuiCssBaseline,
  MuiFormControl,
  MuiFormControlLabel,
  MuiAutocomplete
} from './components'

import { typography } from './typography'

const theme = createTheme({
  typography,
  palette,
  breakpoints: {
    values: breakpoints
  },
  components: {
    MuiAutocomplete,
    MuiSelect,
    MuiCssBaseline,
    MuiFormControl,
    MuiFormControlLabel,
    MuiCheckbox,
    MuiInputBase,
    MuiButton,
    MuiInputLabel,
    MuiTypography
  }
})

export default theme

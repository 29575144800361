import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'
import StorageManager from '@utils/storage-manager'
import { StorageCaseEnum, StorageKeysEnum } from '@appTypes/enums/global'
import { IChangePassword, ILoginView, IResetPassword, ISetPasswordPayload } from '@store/features/auth/auth.types'

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (payload: ILoginView, { rejectWithValue }) => {
    try {
      const { rememberMe, ...payloadData } = payload

      const whereSave = rememberMe ? StorageCaseEnum.LOCAL : StorageCaseEnum.SESSION
      StorageManager.setString(StorageKeysEnum.REMEMBER_ME, whereSave, StorageCaseEnum.LOCAL)

      const tokenResponse = await Api.auth.login(payloadData)

      StorageManager.setString(
        StorageKeysEnum.ACCESS_TOKEN,
        tokenResponse.data.data.accessToken,
        whereSave
      )
      StorageManager.setString(
        StorageKeysEnum.REFRESH_TOKEN,
        tokenResponse.data.data.accessToken,
        whereSave
      )

      const userResponse = await Api.auth.getProfile()

      const user = 'club' in userResponse.data.data

      if (!user) {
        const where = StorageManager.getItem(StorageKeysEnum.REMEMBER_ME)
        StorageManager.removeItem(StorageKeysEnum.ACCESS_TOKEN, where)
        StorageManager.removeItem(StorageKeysEnum.REFRESH_TOKEN, where)

        throw new Error()
      }

      return {
        accessToken: tokenResponse.data.data.accessToken,
        refreshToken: tokenResponse.data.data.refreshToken,
        userData: userResponse?.data?.data || null
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const profileThunk = createAsyncThunk('auth/profile', async (_, { rejectWithValue }) => {
  try {
    const response = await Api.auth.getProfile()

    return { userData: response?.data?.data || null }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const logoutThunk = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    await Api.auth.logout()

    const where = StorageManager.getString(StorageKeysEnum.REMEMBER_ME) as StorageCaseEnum

    StorageManager.removeItem(StorageKeysEnum.ACCESS_TOKEN, where)
    StorageManager.removeItem(StorageKeysEnum.REFRESH_TOKEN, where)
    StorageManager.removeItem(StorageKeysEnum.REMEMBER_ME)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const changePasswordThunk = createAsyncThunk(
  'auth/password-change',
  async (payload: IChangePassword, { rejectWithValue }) => {
    try {
      await Api.auth.changePassword(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const resetPasswordThunk = createAsyncThunk(
  'auth/password-reset',
  async (payload: IResetPassword, { rejectWithValue }) => {
    try {
      await Api.auth.resetPassword(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const setPasswordThunk = createAsyncThunk(
  'auth/password-set',
  async (payload: ISetPasswordPayload, { rejectWithValue }) => {
    try {
      await Api.auth.setPassword(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

import { Body, StyledTypographyDateValue } from '@components/TicketBox/styled'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils/time'
import { DotsWrapper } from '@components/common'
import { TicketCounterStatuses } from '@appTypes/enums/filters'
import { ViewComponentsProps } from '@components/TicketBox/types'
import Courses from '../Courses'
import Members from '../Members'

const OpenTicketsView = ({ page, date, ticket }: ViewComponentsProps) => {
  const { status, offerStatus, availability, comment } = ticket
  return (
    <Body page={page}>
      <Box>
        <Typography component='h5' variant='h5'>
          Date and Time
        </Typography>
        <StyledTypographyDateValue>
          {offerStatus === TicketCounterStatuses.PENDING
            ? formatDate({ date: date.toISOString(), status, availability, offerStatus })
            : formatDate({ date: date.toISOString(), status, availability })}
        </StyledTypographyDateValue>
      </Box>
      <Courses page={page} ticket={ticket} />
      <Members ticket={ticket} />
      <Box>
        <Typography component='h5' variant='h5'>
          Comments
        </Typography>
        <DotsWrapper>
          <Typography component='p'>{comment}</Typography>
        </DotsWrapper>
      </Box>
    </Body>
  )
}

export default OpenTicketsView

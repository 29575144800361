import { memo } from 'react'
import { Typography } from '@mui/material'

import { Section } from './styled'

const NotFound = () => {
  return (
    <Section>
      <Typography component='h4' variant='h4'>
        Page not found.
      </Typography>
    </Section>
  )
}

export default memo(NotFound)

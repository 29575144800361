import { styled } from '@mui/material/styles'
import { Select as MuiSelect } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'

export const StyledMuiSelect = styled(MuiSelect)(() => ({
  width: '100%',
  textAlign: 'left',

  '.MuiSelect-select': {
    width: '100%',
    height: '51px',
    padding: '13px 20px'
  },
  svg: {
    right: '20px'
  },
  fieldset: {
    display: 'none',
    legend: {
      visibility: 'unset'
    }
  }
}))

export const StyledMuiInputLabel = styled(InputLabel)(() => ({
  textAlign: 'left',
  transform: 'unset',
  marginBottom: '10px'
}))

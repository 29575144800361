import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Section = styled(Box)(({ theme }) => ({
  padding: '100px 86px',
  position: 'relative',

  '.MuiFormHelperText-root': {
    textAlign: 'center'
  },
  [theme.breakpoints.down('md')]: {
    padding: '30px 20px 10px 20px'
  }
}))

export const StyledIcon = styled('img')(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer'
}))

export const StyledTypography = styled(Typography)(() => ({
  margin: '30px 0 30px 0',
  textAlign: 'center'
}))

import React from 'react'
import { connect } from 'react-redux'

import { AppDispatch } from '@store/types'
import { logoutThunk } from '@store/features/auth/auth.actions'
import Oops from './components/Oops'

interface IErrorBoundaryProps {
  logoutThunk: any
  children: React.ReactNode
}
class ErrorBoundary extends React.Component<IErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state?.hasError && process.env.NODE_ENV === 'production') {
      this.props.logoutThunk()
      return <Oops />
    }

    return this.props?.children
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logoutThunk: () => dispatch(logoutThunk())
})

export default connect(null, mapDispatchToProps)(ErrorBoundary)

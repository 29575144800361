import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',

  '> div': {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },

  button: {
    width: 'max-content'
  },

  [theme.breakpoints.down('md')]: {
    button: {
      display: 'none'
    }
  }
}))

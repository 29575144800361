import { Box, css, MenuItem, Stack, styled, TextField } from '@mui/material'
import COLORS from '@app/colors'
import { StyledTextFieldLabel } from '@ui/TextField/styled'

import { shouldForwardProp } from '@utils/mui'

export const SelectMembersContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 50px;
    }
  `}
`

export const Selects = styled(Stack)`
  margin-top: 80px;
  gap: 24px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 50px 0;
    }
  `}
`

export const SelectsRow = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`

export const AutocompleteTitle = styled(StyledTextFieldLabel, {
  shouldForwardProp: shouldForwardProp(['isMember', 'hasValue'])
})<{ isMember?: boolean; hasValue?: boolean }>`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  color: ${({ isMember, hasValue }) => {
    if (!hasValue) {
      return 'inherit'
    }

    return isMember ? COLORS.green : 'grey'
  }};
`

export const AutocompleteTextField = styled(TextField)`
  width: 300px;

  .MuiInputBase-root {
    background: white;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`

export const OptionMenuItem = styled(MenuItem)`
  display: flex;
  gap: 5px;

  .currentMemberText {
    display: none;
  }

  :hover {
    .currentMemberText {
      display: block;
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      .currentMemberText {
        display: block;
      }
    }
  `}
`

export const ValidationMessage = styled(Box)`
  text-align: center;
  color: ${COLORS.red};
`

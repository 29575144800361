import { styled, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { COLORS } from '@collections/colors'

export const Container = styled(Box)`
  margin-top: 20px;
`

export const Group = styled(Box)``

export const GroupWrapper = styled(Box)`
  span {
    color: #252525;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`
export const MembersWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const StyledTypography = styled(Typography)`
  margin-top: 4px;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  /* text-overflow: ellipsis; */
  white-space: nowrap;
  /* overflow: hidden; */
  /* max-width: 200px; */

  padding: 8px 12px;
  border-radius: 8px;

  &.member {
    color: ${COLORS.green};

    > svg {
      margin: 3px 5px;

      circle {
        fill: ${COLORS.green};
      }
    }

    span {
      color: ${COLORS.green};
    }

    border: 1px solid ${COLORS.green};
  }

  &.guest {
    color: ${COLORS.black};

    span {
      color: ${COLORS.black};
    }

    border: 1px solid ${COLORS.black};
  }
`

export enum PAGE_ROUTES_BASE_PRIVATE {
  UPDATE_COURSE_BASE = '/update-course'
}

export enum PAGE_ROUTES_PRIVATE {
  EMPTY = '',
  HOME = '/',
  OPEN_TICKETS = '/open-tickets',
  TICKET_HISTORY = '/ticket-history',
  CREATE_COURSE = '/create-course',
  UPDATE_COURSE = '/update-course/:id',
  All_COURSES = '/courses',
  ADD_MEMBER = '/create-member',
  ALL_MEMBERS = '/members',
  REPORTS = '/reports',
  OPEN_TICKETS_ID = '/open-tickets/:id',
  UPLOAD_MEMBERS = '/upload-members',
  CREATE_TICKET = '/create-ticket',
  CHANGE_PASSWORD = '/change-password'
}

export enum PAGE_ROUTES_PUBLIC {
  SIGN_IN = '/auth/sign-in',
  FORGOT_PASSWORD = '/auth/forgot-password',
  RECOVER_PASSWORD = '/auth/recover-password'
}

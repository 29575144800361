import { memo } from 'react'

import { ITicket } from '@appTypes/global'
import { Box, Typography } from '@mui/material'
import RenderTicketCourses from '../RenderTicketCourses'
import { Wrapper } from './styled'

const Courses = ({ page, ticket }: { page: string; ticket: ITicket }) => {
  return (
    <Box>
      <Typography component='h5' variant='h5'>
        Courses
      </Typography>
      <Wrapper>
        <RenderTicketCourses page={page} ticket={ticket} />
      </Wrapper>
    </Box>
  )
}

export default memo(Courses)

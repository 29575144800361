import { memo } from 'react'

import { Button } from '@components/common'
import { ITicket } from '@appTypes/global'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { useAppDispatch } from '@store/hooks'
import { DialogAlertKeysEnum } from '@appTypes/enums/global'
import { setPopUpData } from '@store/features/general/general.slice'
import { useNavigate } from 'react-router-dom'
import { StyledButtonsWrapper } from '../../styled'

interface IButtonsWrapperProps {
  ticket: ITicket
}

const TicketButtonsWrapper = ({ ticket }: IButtonsWrapperProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (!ticket) {
    return null
  }

  return (
    <StyledButtonsWrapper>
      {ticket.status === 'PENDING' ? (
        <>
          <Button
            variant='contained'
            color='primary'
            type='button'
            onClick={() => {
              dispatch(setPopUpData({ popUpType: null, popUpId: null, status: false }))
              navigate(`${PAGE_ROUTES_PRIVATE.OPEN_TICKETS}/${ticket.id}`)
            }}
          >
            Approve
          </Button>

          <Button
            type='button'
            color='secondary'
            variant='outlined'
            onClick={() => {
              dispatch(
                setPopUpData({
                  popUpId: ticket.id,
                  popUpType: DialogAlertKeysEnum.ticketDeclined
                })
              )
            }}
          >
            Decline
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              dispatch(
                setPopUpData({
                  popUpId: ticket.id,
                  popUpType: DialogAlertKeysEnum.ticketCounter
                })
              )
            }}
          >
            Counter
          </Button>
        </>
      ) : (
        <>
          {/* <Button
            variant='contained'
            color='success'
            onClick={() => {
              // handleTicketEdit(ticket.id)
            }}
          >
            Edit
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button> */}
        </>
      )}
    </StyledButtonsWrapper>
  )
}

export default memo(TicketButtonsWrapper)

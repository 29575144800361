import React, { FC } from 'react'

import COLORS from '@app/colors'

interface CircleProps {
  color?: string
}

const Circle: FC<CircleProps> = ({ color = COLORS.black }) => {
  return (
    <svg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='2' cy='2' r='2' fill={color} />
    </svg>
  )
}

export default Circle

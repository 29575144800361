import { memo, useContext } from 'react'

import { useSearchParams } from 'react-router-dom'

import { FiltersParamsKeysEnum, FiltersStatusesEnum } from '@appTypes/enums/filters'

import { Button } from '@components/common'
import { FiltersContext } from '@contexts/filters'
import { Section, StyledApprovedButton, StyledCancelButton } from './styled'

interface IButtonWrapper {
  total?: number
}

const FilterButtonsWrapper = ({ total = 0 }: IButtonWrapper) => {
  const [searchParams] = useSearchParams()
  const { collectQueryParams } = useContext(FiltersContext)

  const handleSearch = (status: FiltersStatusesEnum) => {
    collectQueryParams({ status })
  }

  const approvedButton = (total: number) => (
    <StyledApprovedButton
      type='button'
      color='primary'
      variant='contained'
      onClick={() => handleSearch(FiltersStatusesEnum.APPROVED)}
    >
      Approved {total ? `(${total})` : ''}
    </StyledApprovedButton>
  )

  const declinedButton = (total: number) => (
    <Button
      type='button'
      color='secondary'
      variant='outlined'
      onClick={() => handleSearch(FiltersStatusesEnum.DECLINED)}
    >
      Declined {total ? `(${total})` : ''}
    </Button>
  )

  const cancelButton = (total: number) => (
    <StyledCancelButton
      type='button'
      color='secondary'
      variant='outlined'
      onClick={() => handleSearch(FiltersStatusesEnum.APPROVED_CANCELED)}
    >
      Cancelled {total ? `(${total})` : ''}
    </StyledCancelButton>
  )

  const switchButtons = () => {
    switch (searchParams.get(FiltersParamsKeysEnum.status)) {
      case FiltersStatusesEnum.APPROVED:
        return approvedButton(total)
      case FiltersStatusesEnum.DECLINED:
        return declinedButton(total)
      case FiltersStatusesEnum.APPROVED_CANCELED:
        return cancelButton(total)
      default:
        return ''
    }
  }

  return (
    <Section>
      {!searchParams.has(FiltersParamsKeysEnum.status) ? (
        <>
          {approvedButton(0)}
          {declinedButton(0)}
          {cancelButton(0)}
        </>
      ) : (
        switchButtons()
      )}
    </Section>
  )
}

export default memo(FilterButtonsWrapper)

import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction } from 'react'

export enum TypeEnum {
  FROM = 'dateFrom',
  TO = 'dateTo',
  EMPTY = ''
}

export interface DialogAlertTicketFilterByDateViaState {
  isOpen: boolean
  type: TypeEnum
  values: {
    dateFrom: Dayjs | null
    dateTo: Dayjs | null
  }
}

export interface IDefaultFilterByDate {
  isCalendar: DialogAlertTicketFilterByDateViaState
  setIsCalendar: Dispatch<SetStateAction<DialogAlertTicketFilterByDateViaState>>
}

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const HamburgerIconWrapper = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}))

export const MobileHeaderContainer = styled(Box)<{ isopen: string }>(({ isopen, theme }) => ({
  position: 'fixed',
  zIndex: '1000',
  top: '65px',
  right: isopen ? '0%' : '-100%',
  padding: '20px',
  background: 'white',
  width: '100vw',
  height: '100vh',
  // transition: 'all 0.2s ease-out allow-discrete',

  [theme.breakpoints.down('md')]: {
    width: '100vw',
    height: 'calc(100vh - 65px)'
  }
}))

import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { generateError } from '@utils/errorHandler'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputDataGenerator } from '@utils/generator'
import { ValidationKeysEnum } from '@appTypes/enums/global'
import { changePasswordThunk } from '@store/features/auth/auth.actions'
import { resetExecutionState } from '@store/features/auth/auth.slice'
import { changePasswordFields } from '@collections/fields'

const defaultValues = {
  new: '',
  confirm: ''
}

export const useChangePassword = () => {
  const dispatch = useAppDispatch()
  const [elseError, setElseError] = useState('')

  const { isLoading, status } = useAppSelector((state) => state.auth.execution)

  const methods = useForm({ defaultValues })

  const { reset , setError, register, handleSubmit } = methods

  const handleForm = (data: typeof defaultValues) => {
    dispatch(changePasswordThunk(data))
      .unwrap()
      .then(() => {
        reset()
        setElseError('')
      })
      .catch((response) => {
        generateError<typeof defaultValues>({ error: response, setElseError, setError })
      })
  }

  const fieldsWithPropsAndActions = useMemo(
    () =>
      inputDataGenerator<typeof defaultValues>({
        array: changePasswordFields,
        register,
        validationKey: ValidationKeysEnum.CHANGE_PASSWORD
      }),
    []
  )

  useEffect(() => {
    return () => {
      dispatch(resetExecutionState())
    }
  }, [])

  return {
    status,
    methods,
    elseError,
    isLoading,
    fields: fieldsWithPropsAndActions,
    register,
    handleForm,
    handleSubmit
  }
}

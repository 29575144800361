import { COLORS } from '@collections/colors'

const MuiInputBase = {
  styleOverrides: {
    input: {
      padding: '13px 15px',
      maxHeight: '50px',
      height: '50px',
      borderRadius: '10px',
      background: COLORS.white,

      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #fff inset'
      }
    }
  }
}

export default MuiInputBase

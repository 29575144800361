import { COLORS } from '@collections/colors'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Section = styled(Box)(({ theme }) => ({
  padding: '12px',

  '> p': {
    textAlign: 'center',
    width: '340px',
    height: '50px',
    border: '1px solid rgba(37, 37, 37, 0.5)',
    borderRadius: '10px',
    padding: '13px',
    marginBottom: '10px',
    cursor: 'pointer',

    [theme.breakpoints.down('md')]: {
      maxWidth: '340px',
      width: '100%'
    }
  },
  '.calendar': {
    '> div': {
      ':first-of-type': {
        display: 'none'
      },
      ':last-of-type': {
        display: 'none'
      }
    },
    '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
      color: COLORS.green
    },
    '.MuiButtonBase-root.MuiPickersDay-today': {
      borderRadius: '7.64211px'
    },
    '.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
      background: COLORS.green,
      borderRadius: '7.64211px'
    }
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '300px'
  }
}))

export const FromToWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',

  '> div': {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%',
    height: '50px',
    border: '1px solid rgba(37, 37, 37, 0.5)',
    borderRadius: '10px',
    padding: '13px',
    marginBottom: '10px',
    cursor: 'pointer'
  },
  [theme.breakpoints.down('md')]: {
    '.hideForMobile': {
      display: 'none'
    },
    '> div': {
      alignItems: 'center',
      fontSize: '13px'
    }
  }
}))

export const StyledBackButton = styled(Box)(() => ({
  color: 'rgba(37, 37, 37, 0.5)',
  cursor: 'pointer'
}))

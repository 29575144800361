import { memo, useContext, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'

import { dayJsWrap } from '@utils/time'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers'

import { FiltersContext } from '@contexts/filters'
import { TypeEnum, IDefaultFilterByDate } from '@components/DropDownFilterByDate/types'
import { Button } from '@components/common'
import { Section, StyledBackButton } from '../../styled'

interface IDatePickerSection extends IDefaultFilterByDate {}

const DatePickerSection = ({ isCalendar, setIsCalendar }: IDatePickerSection) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayJsWrap(dayjs().toISOString()))
  const { collectQueryParams } = useContext(FiltersContext)

  return (
    <Section>
      <StyledBackButton
        onClick={() => {
          setIsCalendar((prev) => {
            return { ...prev, type: TypeEnum.EMPTY, isOpen: false }
          })
        }}
      >
        {`< back`}
      </StyledBackButton>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <StaticDatePicker
            defaultValue={
              isCalendar.type === TypeEnum.TO
                ? isCalendar?.values?.dateTo
                : isCalendar?.values?.dateFrom
            }
            className='calendar'
            onChange={(date) => {
              if (dayjs.isDayjs(date)) {
                setSelectedDate(date)
              }
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <Button
        variant='contained'
        color='primary'
        type='button'
        onClick={() => {
          setIsCalendar((prev) => {
            const { type } = prev

            collectQueryParams({
              [type]: selectedDate.format('YYYY-MM-DD')
            })

            return {
              ...prev,
              type: TypeEnum.EMPTY,
              isOpen: false,
              values: {
                ...prev.values,
                [type]: selectedDate
              }
            }
          })
        }}
      >
        Select a date
      </Button>
    </Section>
  )
}
export default memo(DatePickerSection)

import { TicketCounterStatuses } from '@appTypes/enums/filters'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils/time'

const RequestedAtRender = ({ ticket, availability }: any) => {
  const { offerStatus, status } = ticket

  return (
    <Box>
      <Typography component='h5' variant='h5'>
        Requested At
      </Typography>
      <Typography component='p'>
        {offerStatus === TicketCounterStatuses.PENDING
          ? formatDate({ date: ticket?.createdAt, status, availability, offerStatus, one: true })
          : formatDate({ date: ticket?.createdAt, status, availability, one: true })}
      </Typography>
    </Box>
  )
}

export default RequestedAtRender

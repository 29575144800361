import React, { FC } from 'react'

import { Box, ButtonProps } from '@mui/material'

import arrowRight from '@assets/icons/arrow-right.svg'
import { BackButton, NextButton, StepperButtonsContainer } from './styled'

interface StepperButtonsProps {
  nextButtonText?: string
  backButtonText?: string
  backButtonProps?: ButtonProps
  nextButtonProps?: ButtonProps
}

const StepperButtons: FC<StepperButtonsProps> = ({
  backButtonProps,
  nextButtonProps,
  nextButtonText,
  backButtonText
}) => {
  return (
    <StepperButtonsContainer>
      <BackButton variant='text' data-testid='back-button' {...backButtonProps}>
        {backButtonText}
      </BackButton>
      <NextButton
        variant='contained'
        data-testid='next-button'
        color='success'
        endIcon={<img src={arrowRight} alt='arrow' />}
        {...nextButtonProps}
      >
        {nextButtonText}
      </NextButton>
    </StepperButtonsContainer>
  )
}

StepperButtons.defaultProps = {
  backButtonText: 'Back',
  nextButtonText: 'Confirm and Proceed'
}

export default StepperButtons
